import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

// the translations
// (tip move them in a JSON file and import them)
const resources = {
  en: {
    translation: {
      components: {
        isinSearch: {
          placeholder: 'ISIN / WKN / Fond Name ...',
          applyButton: 'Query discount',
        },
        advantagesTable: {
          headers: {
            first: 'Ihr Vorteil',
            second: 'als Fondsdiscounter 2.0',
            third: 'Fondsdiscounter 1.0',
          },
        },
        layouts: {
          redesign: {
            header: {
              actions: {
                fundDiscount: 'Fund discount',
                yourBenefits: 'Your benefits',
                conditions: 'Conditions',
                depotManagerApp: 'Depot Management App',
                aboutUs: 'About us',
                knowledge: 'Knowledge',
                login: 'Login',
                becomeCustomer: 'Become a customer',
                inApp: 'Home',
                cashback: 'Apply for cashback',
                faqs: 'FAQs',
                forum: 'Forum',
                blog: 'Blog',
                support: 'Support',
              },
            },
            footer: {
              copyright: 'A trademark of wealthAPI GmbH',
              column1: {
                title: 'About us',
                items: [
                  // {
                  //   href: 'https://wealthapi.eu/ueber-uns/',
                  //   title: 'About us',
                  // },
                  {
                    href: 'https://wealthapi.eu/karriere/',
                    title: 'Career',
                  },
                  {
                    href: 'https://www.financequality.net/partnerprogramme/rentablo-fond-cashback/',
                    title: 'Partner program',
                  },
                  {
                    href: 'https://wealthapi.eu',
                    title: 'Commercial customers',
                  },
                ],
              },
              column2: {
                title: 'Funds-Cashback',
                items: [
                  {
                    href: 'https://rentablo.de/software-depotverwaltung',
                    title: 'Portfolio Management Software',
                  },
                  {
                    href: 'https://rentablo.de/portfolio-performance',
                    title: 'Portfolio Performance',
                  },
                  {
                    href: 'https://rentablo.de/portfolio-optimierung',
                    title: 'Optimize Portfolio',
                  },
                  {
                    href: 'https://rentablo.de/kostenloser-finanzmanager-online',
                    title: 'Free finance manager',
                  },
                ],
              },
              column3: {
                title: 'Portfolio Software',
                items: [
                  {
                    href: 'https://forum.rentablo.de/',
                    title: 'Forum and FAQ',
                  },
                  {
                    href: 'https://blog.rentablo.de/',
                    title: 'Finance Blog',
                  },
                  {
                    href: 'https://rentablo.de/elektronisches-haushaltsbuch',
                    title: 'Electronic household book',
                  },
                  {
                    href: 'https://rentablo.de#cashback-calculator',
                    title: 'Funds Commission Calculator',
                  },
                ],
              },
              subFooterItems: [
                {
                  href: 'https://rentablo.de/v2/impressum',
                  title: 'Impressum',
                },
                {
                  href: 'https://rentablo.de/v2/privacy',
                  title: 'Data protection',
                },
                {
                  href: 'https://rentablo.de/v2/agb',
                  title: 'Terms',
                },
                {
                  href: 'https://rentablo.de/v2/disclaimer',
                  title: 'Disclaimer',
                },
              ],
            },
          },
        },
      },
      ieAlert: {
        title: 'Your browser IE is not supported.',
        description:
          'The page most likely will not work correctly. Please download Chrome, Opera, Firefox or Edge',
      },
      structuralData: {
        webSite: {
          name: 'Rentablo - Fondsdiscount 2.0 und Portfoliomanager',
          description:
            'Rentablo macht Fondssparen rentabler - inklusive Deutschlands bestem Portfoliomanager',
        },
        headerImage: { caption: 'Rentablo - macht Fondssparen rentabler' },
      },
      customerService: {
        phoneLink: '03071533009',
        phone: '030 - 7153 3009',
        email: 'info@rentablo.de',
        emailOnvista: 'onvista-cashback@rentablo.de',
        supportTime: 'Montag bis Freitag von 9 bis 19 Uhr erreichbar',
      },
      landingMain: {
        claim: 'Rentablo - macht Fondssparen rentabler',
        title: 'Fondsdiscount 2.0: Fonds ohne Ausgabeaufschlag PLUS Cashback ',
        keywords:
          'Fondssparen, Fondssparer, Software Depotverwaltung, Finanzmanager, Portfoliomanager,  Portfolio Performance',
        description:
          'Doppelt sparen bei Investmentfonds: "Sehr gut” im Fondsvermittler Test von Börse Online, bekannt aus Stiftung Warentest.',
        pageTopics:
          'Fondssparen, Fonds-Cashback, Software Depotverwaltung, Finanzmanager, Portfoliomanager, Portfolio Performance',
        factsBar: {
          funds: '24.000 funds',
          partnerBanks: '8 Partner banks',
          provenExpert: '4.8 Stars (Proven Expert)',
          cost: '0 Euro cost',
          simpleAndFair: 'Simple, fair and secure',
        },
        yourAdvantages: {
          title: 'Ihre <mainText>exklusiven Vorteile</mainText> mit Rentablo',
          subtitle:
            'Wir bieten Ihnen nicht nur Agio-Rabatt und Cashback, sondern auch absolute Transparenz, höchste Sicherheit, riesige Zeitersparnis und einen maximalen Überblick über Ihre Investments',
          checklist: {
            title: 'Ihre Vorteils-Checkliste',
            button: 'Jetzt Fonds-Cashback beantragen',
          },
        },
        headerInfo: {
          funds: '25.000+ funds',
          partnerBanks: '7 Partner banks',
          cost: '0 Euro cost',
          simpleAndFair: 'Simple, fair and secure',
        },
        mainScreen: {
          title: {
            first: '<mainText>Fund discounter</mainText>',
            second: 'with double savings',
          },
          startNow: 'Start now',
          learnMore: 'Learn more',
        },
        cashbackCalculator: {
          title: '<mainText>Fund Discount Calculator</mainText>',
          subTitle: '<greyText>Calculate you advantage</greyText>',
        },
        checkmarksList: {
          first: 'Up to 100% Premium Discount',
          second: 'Up to 100% Cashback',
          third: 'Guaranteed free',
        },
        cashbackIntro: {
          title: 'Doppelt sparen bei Investmentfonds',
          subTitle: 'Fondsdiscount 2.0',
          startNow: 'Jetzt Starten',
          claim1: 'Fonds ohne Ausgabeaufschlag kaufen',
          claim2: 'Zusätzliche Gutschrift aus den internen Fondsprovisionen jedes Quartal',
          learnMore: 'Mehr erfahren',
          toPortfolioManager: 'zum Portfolio-Manager 2.0',
        },
        financeManagerIntro: {
          title: 'Depot-Performance einfach messen',
          subTitle: 'Portfoliomanager 2.0',
          startNow: 'Jetzt Starten',
          claim1: 'Portfolio-Performance bankübergreifend',
          claim2: 'automatisierter Datenimport',
          learnMore: 'Mehr erfahren',
        },
        fundsCashbackBox: {
          heading: 'Funds-Cashback',
          weGetYourFeesBack: 'Get your hidden fees back!',
          discountOnAgio: '<0>100 % discount</0> on Agio',
          discountOnPortfolioCommission: 'up to <1>100 %</1> of portfolio commission back',
          alsoForCompanyDepots: 'Also for <1>companies and foundations</1>',
          bookNow: 'Book Cashback now',
          bookNowXs: 'Book now',
          forFree: 'For free - guaranteed',
          calculateNow: 'Or calculate cashback now',
        },
        financeManagerBox: {
          including: 'including',
          germaniesBestFinanceManager: 'Germanies best Finance Manager',
          financeManager: 'Wealth-Management Software',
          manageAllFinances: 'Your Wealth under full control',
          learnMore: 'Learn more',
        },
        expertSupportBox: {
          including: 'including',
          expertSupport: 'Expert Support',
          realPeople: 'real people',
          realKnowHow: 'real know how',
        },
        fundsDiscounter: {
          title: 'Fondsdiscount  2.0',
          titleSmall: 'Fondsdiscount  2.0',
          subTitle: 'already over',
          message: '3.000.000 € Cashback',
          subMessage: 'paid out to our customers',
          subMessageSmall: 'paid out to customers',
        },
        wellKnown: {
          title: 'Well known',
          imgTitle: 'Well known',
        },
        partnerBanks: {
          title: 'Rentablo partner banks',
          subtitle: 'Click for details',
        },
        simpleCalculator: {
          title: 'Calculate your cashback advantage:',
        },
        detailCalculator: {
          title: 'Calculate advantage now:',
          subtitle:
            'Gut zu wissen: Selbst wenn Sie die Fondsanteile bereits gekauft haben, können Sie immer noch an der laufenden Bestandsprovision (Cashback) partizipieren, indem Sie zu Rentablo wechseln.',
        },
        faq: {
          title: 'FAQs',
          applyButton: 'Apply for Funds-Cashback now',
          questions: [
            {
              question: 'What does Rentablo do?',
              answer:
                'Rentablo ist ein Fondsdiscounter, aber in der Version 2.0. Wie bei anderen Fondsdiscountern können Sie mit Rentablo fast alle Fonds ohne Ausgabeaufschlag kaufen. Rentablo geht beim Cashback noch einen Schritt weiter: Als Rentablo-Kunde erhalten Sie bis zu 100 Prozent der Bestandsprovision eines Fonds erstattet, und zwar quartalsweise.  Wie viel, hängt vom Fonds und der jeweiligen Depotbank ab. Sie können das direkt hier auf der Seite ausrechnen.',
            },
            {
              question: 'How do I book Cashback?',
              answer:
                'Sie können Ihr Fonds-Cashback ganz einfach auf unserer Homepage beantragen. Eine Schritt-für-Schritt Anleitung finden Sie auf unserem Forum:',
              forumLinkText: 'How to book Cashback',
              forumLinkHref:
                'https://forum.rentablo.de/t/rentablo-cashback-buchen-beantragen-wie-geht-das/928',
            },
            {
              question: 'Is Rentablo a Bank?',
              answer:
                'Nein, Rentablo ist keine Bank, aber von der BaFin (Aufsicht) kontrolliert. Im Gegensatz zu einer Bank jedoch, verkaufen wir Ihnen nichts. Rentablo ist ein Service für Selbstentscheider bei Finanzangelegenheiten. Sie handeln und entscheiden immer selbst. Sie erhalten weder Anlageberatung noch irgendwelche "heißen Tipps", was Sie kaufen sollten. Via Rentablo Cashback holen Sie einen großen Teil der Fondskosten zurück, die sonst bei der Bank verbleiben oder von Ihrem Finanzvermittler einbehalten werden. Mit dem kostenlosen Rentablo Portfoliomanager können Sie zudem bequem Ihre Finanzen verwalten, analysieren und optimieren.',
            },
            {
              question: 'Where are my funds kept?',
              answer:
                'Ihre Fondsanteile sind und bleiben da, wo sie immer sind: bei Ihrer Depotbank. Rentablo darf weder Ihre Fondsanteile verkaufen noch Fondsanteile für Sie kaufen. Wie gesagt: Das ist ein Service für Selbstentscheider. Bei Ihrer bisherigen oder neuen Depotbank gibt es nur einen Betreuerwechsel. Rentablo wird als Ihr Betreuer eingetragen und verzichtet sofort auf Abschlussprovisionen (Ausgabeaufschläge) und schüttet Bestandsprovisionen als Cashback zu den hervorragenden Rentablo-Konditionen an Sie aus.',
            },
            {
              question: 'What do I have to do?',
              answer:
                'Nicht viel. Auf dieser Seite sehen Sie die acht Partnerbanken, mit denen Rentablo zusammenarbeitet. Wenn Sie bereits Fondsanteile bei einer dieser Depotbanken verwahren,ist es besonders einfach. Sie beantragen das Rentablo-Cashback und damit lediglich einen Betreuerwechsel. Das dauert nur wenige Minuten. Wenn Ihre Depotbank hier nicht aufgeführt sein sollte, ist es notwendig, ein neues Depotkonto mit Rentablo als Betreuer bei einer der acht Partnerbanken zu eröffnen. Das geht ebenfalls innerhalb weniger Minuten.',
            },
            {
              question: 'What does Rentablo cost?',
              answer:
                'Cashback wird Sie niemals etwas kosten - Sie werden immer ein Plus erzielen (auch wenn wir etwas daran verdienen). Ehrenwort! Wovon wir leben? Von einem Teil der Bestandsprovision, der für den Service bei uns bleibt. Cashback erhalten Sie bereits vom ersten investierten Euro an. Es gibt keine Mindestdepotgröße. Sie erhalten von Anfang an 50 % der an uns ausgeschütteten Bestandsprovision (Cashback-50, lt. Stiftung Warentest bis zu einer Depotgröße von ca. 75.000 €). Unser Anteil bleibt jedoch bei 15 €/Monat stehen. Darüber hinaus gehende Beträge erstatten wir Ihnen zu 100% (Cashback-100).',
            },
            {
              question: 'Where can I learn more?',
              answer:
                'Wir haben uns die Finger wund geschrieben und alles  auf diese Internetseite gepackt. Sie finden dort auch die Links zu den zahlreichen Berichten, die Finanzexperten etwa von der Stiftung Warentest über uns verfasst haben. Aber so schön das Internet auch ist: Manche Frage klärt sich schneller von Mensch zu Mensch. Deshalb zögern Sie nicht, uns eine Mail zu schicken oder anzurufen: Wir sind gerne für Sie da.',
              forumLinkText: 'Many questions have been answered on the Rentablo Forum.',
              forumLinkHref: 'https://forum.rentablo.de/c/rentablo-cashback',
            },
          ],
        },
        counters: {
          title: 'Our customers have so far...',
          subtitleLeft: 'One-time commission saved',
          subtitleRight: 'Cashback received',
        },
        callToAction: {
          title: 'More profitable savings now!',
          subTitleType1: 'Get your money back and secure your cashback bonus!',
          subTitleType2: 'Fast, easy and safe more return!',
          applyButton: 'Apply for Funds-Cashback now',
        },
        testimonials: {
          clientsTitle: 'Clients say',
          expertsTitle: 'Experts say',
          bothTitle: 'Clients and Experts say',
          willkomm: {
            name: 'Vincent Willkomm',
            description: 'Finanzblogger, Hamburg',
            text: 'Um die Kosten von aktiven Investmentfonds in Richtung ETF-Niveau zu drücken, nutze ich das Rentablo Cashback. Das garantiert die höchste Rückerstattung von Provisionen.',
          },
          siebers: {
            name: 'Torsten Siebers',
            description: 'Unternehmensberater, Hamburg',
            text: 'Als Nutzer bin ich immer wieder begeistert, mit welchem Enthusiasmus sich das Team von Rentablo dem Verbraucherschutz widmet und mit Rentablo Cashback auch umsetzt.',
          },
          bukow: {
            name: 'E. Bukow',
            description: 'kfm. Angestellte, Düsseldorf',
            text: 'Besten Dank Rentablo! Die Abrechnungen vom Cashback kommen pünktlich und werden auf den Cent genau ausgewiesen.',
          },
          konetzke: {
            name: 'Toralf Konetzke',
            description: 'Unternehmer und Profisportler, Cottbus',
            text: 'Mit dem Cashback habt Ihr bei mir einen Elfmeter verwandelt. Kosten runter und Rendite hoch - perfekt! Danke und weiter so!',
          },
          ingeborg: {
            name: 'Ingeborg K.',
            description: 'Pensionärin, Nürnberg',
            text: 'Ich nutze Rentablo Cashback bereits seit Januar 2018 und freue mich seitdem über pünktliche Auszahlungen von mehr als 1.200 Euro im Quartal.',
          },
          handelsblatt: {
            title: 'Handelsblatt',
            text: '',
          },
          ard: {
            title: 'börse ARD',
            text: '',
          },
          br: {
            title: 'BR',
            text: '',
          },
          fondsProfessionell: {
            title: 'Fonds-Professionell',
            text: '',
          },
          tagesschau: {
            title: 'Tagesschau',
            text: '',
          },
          sueddeutsche: {
            title: 'Süddeutsche Zeitung',
            text: 'Wer in Fonds anlegt, muss auf die Kosten achten. Jetzt werben Anbieter mit der Erstattung von Provisionen.',
          },
          boersede: {
            title: 'boerse.de',
            text: '',
          },
          borseonline: {
            title: 'Börse Online',
            text: '',
          },
          welt: {
            title: 'Die Welt, Future of Finance',
            text: 'Wer sein Vermögen in Zeiten des Nullzinses erhalten will, muss es diversifizieren. Hierzu bieten sich Investment-Fonds an - leider oft zu überhöhten Gebühren. Mit seinen Cashback-Modellen bietet das FinTech Rentablo Anlegern erstmals die Möglichkeit, sich bis zu 100% der Provisionen zurückzuholen.',
          },
          capital: {
            title: 'Capital',
            text: '',
          },
          wiwo: {
            title: 'Wirtschaftswoche',
            text: '...das Fintech Rentablo, “eine Mischung aus Aldi und Deutsche Bank”.',
          },
          finanztest: {
            title: 'Stiftung Warentest / Finanztest',
            text: 'Das besondere Angebot von rentablo.de: Kunden, die dort das Depotmodell "Cashback-100" buchen, erhalten gezahlte Bestands­provisionen und Ausgabe­aufschläge in der Regel voll­ständig zurück.',
          },
          focusMoney: {
            title: 'Focus Money, Fairstes Produktangebot',
            text: 'Savedo und Rentablo bieten das beste Preis-Leistungs-Verhältnis.',
          },
          versicherungsmagazin: {
            title: 'Versicherungsmagazin, Fintech unter der Lupe',
            text: 'Rentablo - Leidenschaftliche Investoren mit Sinn fürs Kollektiv',
          },
        },
      },
      redesignMainPage: {
        cashbackIntro: {
          title: 'Doppelt sparen bei Investmentfonds.',
          subTitle: 'Fondsdiscount 2.0',
          startNow: 'Jetzt Starten',
          description:
            'Fonds ohne Ausgabeaufschlag kaufen.  Zusätzliche weitere Gutschriften als Fonds-Cashback erhalten. Quartalsweise.',
          learnMore: 'Mehr erfahren',
          toPortfolioManager: 'zum Portfolio-Manager 2.0',
        },
        financeManagerIntro: {
          title: 'Depot Performance. Einfach und messbar.',
          subTitle: 'Depot Management App',
          startNow: 'Jetzt Starten',
          description:
            'Everything in view. Manage and analyze cross-bank accounts and custody accounts. Automated data import.',
          learnMore: 'Mehr erfahren',
        },
        preview: {
          videoBlock: {
            tag: 'Rentablo fund cashback',
            title: 'With us you benefit from the first second.',
            subtitle: 'You never have to pay anything - guaranteed free!',
            action: 'Start now',
            link: 'Calculate cashback now',
          },
          application: {
            header: {
              title: '<whiteText>Kostenlos inklusive.</whiteText>',
              title2: '<greyText>Die Rentablo Depotverwaltungs-App.</greyText>',
            },
            finance: {
              subtitle: 'Finance director',
              title: 'Your entire finances at a glance',
            },
            oneClick: {
              subtitle: 'One click import',
              title: 'Seamless import of your accounts and custody accounts',
            },
            evaluate: {
              subtitle: 'Evaluate',
              title: 'Recognize risks',
            },
            setGoals: {
              subtitle: 'set goals',
              title: 'Achieve the desired return',
            },
            moreFinancial: {
              text: 'More financial overview with the Rentablo custody account management app',
              btn: 'Test now',
            },
          },
        },
        wellKnown: {
          title: 'Known from',
        },
        testimonials: {
          welt: {
            title: 'Die Welt, Future of Finance',
            text: 'Wer sein Vermögen in Zeiten des Nullzinses erhalten will, muss es diversifizieren. Hierzu bieten sich Investment-Fonds an - leider oft zu überhöhten Gebühren. Mit seinen Cashback-Modellen bietet das FinTech Rentablo Anlegern erstmals die Möglichkeit, sich bis zu 100% der Provisionen zurückzuholen.',
          },
          wiwo: {
            title: 'Wirtschaftswoche',
            text: '...das Fintech Rentablo, “eine Mischung aus Aldi und Deutsche Bank”.',
          },
          finanztest: {
            title: 'Stiftung Warentest / Finanztest',
            text: 'Das besondere Angebot von rentablo.de: Kunden, die dort das Depotmodell "Cashback-100" buchen, erhalten gezahlte Bestands­provisionen und Ausgabe­aufschläge in der Regel voll­ständig zurück.',
          },
          focusMoney: {
            title: 'Focus Money, Fairstes Produktangebot',
            text: 'Savedo und Rentablo bieten das beste Preis-Leistungs-Verhältnis.',
          },
        },
        experienced: {
          logoAlt: 'Rentablo',
          title: 'Established & Experienced.',
          subtitle:
            "Rentablo has been on the market for a long time - we've been around since 2014.",
        },
      },
      redesignCashbackPage: {
        cashbackForFunds: {
          subTitle: 'Cashback for funds',
          title: 'Fund discounter with double savings',
          learnMore: 'Calculate cashback now',
          startNow: 'Book now',
        },
        cashbackCalculator: {
          title: 'Cashback Calculator',
        },
        fundDiscount: {
          title: 'So funktioniert unser <mainText>Fondsdiscount</mainText>',
          description:
            'Rentablo ist ein Fondsdiscounter, und zwar der Version 2.0: Wie bei anderen Fondsdiscount-Anbietern kaufen Sie Tausende Fonds ohne Ausgabeaufschlag, also ohne Abschlussprovisionen. Zusätzlich schüttet Rentablo laufende Bestandsprovisionen an Sie aus. Sie sparen mit uns doppelt - deshalb Fondsdiscount 2.0.',
          oneStep: {
            title: 'Berechnen Sie sofort, wie viel Sie bei der Fondsanlage sparen können',
            imageTitle: 'Die Ersparnis beim Fondsdiscount 2.0',
            description:
              'Der maximale Rabatt beim Ausgabeaufschlag sowie die Ausschüttung von Bestandsprovision (Cashback) hängen ab von der Depotbank und den Fonds, die Sie gewählt haben. Geben Sie im Rechner Ihre Fonds sowie die jeweilige Anlagesumme an. Sie erhalten sofort das Ergebnis, wie groß Ihr finanzieller Vorteil mit Fondsdiscount von Rentablo ist.<separator /><separator />Sehr wichtig: Sie erhalten Cashback auch für Fondsanteile, die sich bereits in Ihrem Depots befinden!',
          },
          custodianBank: {
            title: 'Doppelte Ersparnis: einfach und sicher',
            imageTitle: 'Unsere Auszahlungen als Fondsdiscounter',
            description:
              'Um als Fondsanleger von den Rentablo-Konditionen profitieren zu können, benötigen Sie lediglich ein Wertpapier-Depot bei einer unserer sechs günstigen Partnerbanken. Vielleicht haben Sie dort bereits ein Depot, dann ist lediglich ein Wechsel des Fondsvermittlers in den Depotdaten notwendig. Oder Sie eröffnen ganz einfach ein neues Depot mit Rentablo als Fondsvermittler. Das dauert nur etwa fünf Minuten.<separator /><separator />Unser Fondsdiscount ist sicher: Ihr Depot und die darin enthaltenen Wertpapiere liegen immer bei der Depotbank. Als Ihr Fondsvermittler haben und wollen keinen Zugriff auf Ihre Fondsanteile, noch können wir Aufträge für Sie ausführen.',
          },
          applyingCashback: {
            title: {
              firstLine: 'Weniger Kosten.',
              secondLine: 'Mehr Rendite.',
            },
            imageTitle: 'So gross ist der Mehrertrag mit Fondsdiscount (Rechenbeispiel)',
            description:
              'Mit Cashback steigern Sie Ihre Fondsrendite beträchtlich<separator /><separator />Fondsdiscount mit 100 % Rabatt auf den Ausgabeaufschlag beim Fondskauf ist bereits eine große, einmalige Ersparnis. Das Cashback beim Rentablo Fondsdiscount 2.0 kommt dauerhaft hinzu, so lange Sie die Fondsanteile halten. Die Ausschüttung wird jedes Quartal neu berechnet und ausgeschüttet.<separator /><separator />Steigt Ihr Fondsvolumen durch Kurszuwächse, steigt auch Ihr Cashback! Im Schnitt betragen die Bestandsprovisionen 0,56 % p.a. des Fondsvolumens - die Ausschüttung wird zu Ihrer Zusatzrendite beim Fondssparen.',
          },
          cashbackNumber: {
            currency: 'Mio+ €',
            cashback: 'Cashback',
            title: 'bereits ausgezahlt',
          },
        },
        cashbackStepper: {
          title:
            'Cashback ist <mainText>kostenfrei</mainText> und <mainText>einfach</mainText> zu beantragen',
          subtitle:
            'Wovon wir leben? Von einem Teil der Bestandsprovision, der für den Service bei uns bleibt. Cashback erhalten Sie bereits vom ersten investierten Euro an. Es gibt keine Mindestdepotgröße.',
          steps: {
            first: {
              title: 'Schritt 1',
              description: 'Daten eingeben',
            },
            second: {
              title: 'Schritt 2',
              description: 'Ausdrucken',
            },
            third: {
              title: 'Schritt 3',
              description: 'An uns senden',
            },
          },
          actions: {
            startNow: 'Jetzt direkt starten',
            startNowDescription:
              'Ihre Buchung wird erst verbindlich, wenn Sie uns Ihren Antrag per Post schicken',
          },
        },
        conditions: {
          title: '<mainText>Our conditions</mainText>',
          subtitle: 'Partner banks in comparison',
        },
        testimonials: {
          title: "That's what customers say",
          subtitle: '<greyText>See for yourself</greyText>',
          cards: {
            vincentWillkomm: {
              name: 'Vincent Willkomm',
              profession: 'Finanzblogger, Hamburg',
              text: '“Um die Kosten von aktiven Investmentfonds in Richtung ETF-Niveau zu drücken, nutze ich das Cashback. Das garantiert die höchste Rückerstattung von Provisionen.”',
            },
            ingeborgK: {
              name: 'Ingeborg K.',
              profession: 'Pensionärin, Nürnberg',
              text: '“Ich nutze Rentablo Cashback bereits seit Januar 2018 und freue mich seitdem über pünktliche Auszahlungen von mehr als 1.200 Euro im Quartal.”',
            },
            konetzke: {
              name: 'Toralf Konetzke',
              profession: 'Unternehmer und Profisportler, Cottbus',
              text: '“With the cashback you converted a penalty for me. Costs down and returns up - perfect! Thank you and keep it up!”',
            },
            siebers: {
              name: 'Torsten Siebers',
              profession: 'Unternehmensberater, Hamburg',
              text: '“As a user, I am always amazed at the enthusiasm with which the Rentablo team devotes itself to consumer protection and implements it with fund cashback.”',
            },
          },
        },
        faq: {
          title: 'Have questions?',
          subtitle: '<greyText>A few answers to frequently asked questions.</greyText>',
          questions: [
            {
              title: 'Wie buche ich Fondsdiscount mit Fonds-Cashback?',
              text: 'Für den Fondskauf ohne Ausgabeaufschläge und Fonds-Cashback für bereits bestehende als auch neue Fondsanteile klicken Sie den Button “Jetzt starten” auf unserer Homepage. Folgen Sie nun der Schritt-für-Schritt Anleitung. Sie erhalten am Ende ein PDF,  welches Sie bitte an uns weiterleiten. Sollten Fragen aufkommen, melden Sie sich gerne bei unserem Kundenservice.',
            },
            {
              title: 'Was ändert sich für mich als Fondssparer?',
              text: 'Beim Kauf von neuen Fondsanteilen sparen Sie künftig erheblich Abschlusskosten (Ausgabeaufschlag), außerdem erhalten Sie laufende Fondsgebühren als Fonds-Cashback erstattet. Ansonsten ändert sich nichts! Sie handeln Ihre Wertpapiere weiterhin wie gewohnt über Ihre Depotbank. Ihre Zugangsdaten dort ändern sich nicht. Ihre Fondsanteile sind und bleiben da, wo sie immer sind: bei Ihrer Depotbank.',
            },
            {
              title: 'Wann erfolgt die Auszahlung vom Fonds-Cashback?',
              text: 'Die Abrechnung und Auszahlung erfolgt quartalsweise; aufgrund der Abrechnungszyklen der Depotbanken können wir Ihnen erst zwei Monate nach Ablauf des jeweiligen Quartals Ihr individuelles Fonds-Cashback auszahlen.<br/>Auszahlungstermine sind demnach für das: <ol><li>Quartal, der 1. Juni</li><li>Quartal, der 1. September</li><li>Quartal, der 1. Dezember</li><li>Quartal, der 1. März des Folgejahres.</li></ol>',
            },
            {
              title: 'Wie verdient Rentablo Geld?',
              text: 'Bei Rentablo haben Sie keine Kosten, denn wir arbeiten ausschließlich erfolgsorientiert. <br />Sie werden immer ein Plus erzielen. Ehrenwort! Wovon wir leben? Von einem Teil der Bestandsprovision, der für den Service bei uns bleibt. Wir teilen uns die erhaltenen Bestandsprovisionen bis zu einer maximalen Grenze von 15,00 € im Monat. Ist diese Grenze erreicht, erhalten Sie die weitere Bestandsprovisionen zu 100 % ausgeschüttet. Weitere Informationen finden Sie hier im Forum: <br/><a href="https://forum.rentablo.de/t/was-kostet-rentablo-cashback-fuer-investmentfonds/626" target="_blank" rel="noreferrer">https://forum.rentablo.de/t/was-kostet-rentablo-cashback-fuer-investmentfonds/626.</a>',
            },
            {
              title: 'Erhalte ich eine Abrechnung?',
              text: 'Ja, natürlich! Sie erhalten zu jeder Fonds-Cashback-Ausschüttung eine genau Abrechnung. Diese können Sie in Ihrem Rentablo-Kundenkonto jederzeit einsehen. Rentablo steht für Transparenz, deswegen finden Sie unter "Details" die Höhe der Bestandsprovisionen zu jedem einzelnen Fonds.',
            },
            {
              title: 'Ich habe kein Depot bei einer der Partnerbanken - was tun?',
              text: 'Eröffnen Sie über uns ein neues Depot! Auf dieser Seite sehen Sie die sechs Partnerbanken, mit denen Rentablo zusammenarbeitet. Wenn Ihre Depotbank hier nicht aufgeführt sein sollte, ist es notwendig, ein neues Depotkonto mit Rentablo als Betreuer bei einer der Partnerbanken zu eröffnen. Das dauert nur wenige Minuten. Hier finden Sie eine Übersicht über unsere Sonderkonditionen bei den Partnerbanken:<br/><a href="https://rentablo.de/cashback/comdirect" target="_blank" rel="noopener">https://rentablo.de/cashback/comdirect</a>',
            },
            {
              title: 'Ich habe bereits einen Vermittler in meinem Depot - wie gehe ich vor?',
              text: 'Beantragen Sie einen Betreuerwechsel! Wenn Sie bereits Fondsanteile bei einer dieser Depotbanken verwahren, ist es besonders einfach. Sie beantragen das Rentablo-Cashback und damit lediglich einen Betreuerwechsel. Haben Sie bereits einen Vermittler in Ihrem Depot, ist keine Kündigung notwendig. Der aktuelle Vermittler wird ausgetragen und Rentablo als neuer Vermittler im Depot eingetragen',
            },
            {
              title: 'Kann Rentablo in meinem Namen handeln?',
              text: 'Rentablo schließt den Handel aus. Wir werden weder Ihre Fondsanteile verkaufen noch Fondsanteile für Sie kaufen. Rentablo ist ein Service für Selbstentscheider bei Finanzangelegenheiten. Sie handeln und entscheiden immer selbst. Sie erhalten weder Anlageberatung noch irgendwelche "heißen Tipps", was Sie kaufen sollten.',
            },
            {
              title: 'Ist Rentablo eine Bank?',
              text: 'Nein, Rentablo ist keine Bank, sondern ein Discount-Fondsvermittler. Im Gegensatz zu einer Bank und beratenden Fondsvermittern verkaufen wir Ihnen nichts. Rentablo ist ein Service für Selbstentscheider mit dem Ziel, für Selbstentscheider die Kosten bestmöglich zu senken und damit die Fondsrendite zu erhöhen.',
            },
            {
              title: 'Gibt es Fonds-Cashback auch bei ETFs?',
              text: 'Cashback gibt es i.d.R. für aktiv gemanagten Fonds, nicht für ETFs. Die Ankaufs- und Verkaufskosten bei ETFs sind ausserdem alleine zu tragen. Der Rabatt auf den Ausgabeaufschlag ist i.d.R. nur für aktiv gemanagte Fonds möglich. ETFs können aber in den gleichen Wertpapier-Depots gehalten werden und über unsere Partnerbanken gekauft und verkauft werden. Hier finden Sie einen Blog-Artikel über den Kostenvergleich zwischen ETFs und aktiv gemanagten Fonds: <a href="https://blog.rentablo.de/etf/" target="_blank" rel="noopener">https://blog.rentablo.de/etf/</a>',
            },
          ],
        },
        questions: {
          title: 'Further questions?',
          subtitle: 'We also answer many questions in our forum.',
          button: 'Go to the Rentablo forum',
          help: {
            name: 'Ina Ketzenberg',
            description: 'Customer support Rentablo',
          },
        },
      },
      cashback: {
        50: 'Cashback-50',
        100: 'Cashback-100',
        title: 'Wir holen Ihre Gebühren zurück',
        keywords: 'Fondssparen, Fondssparer, Fonds-Cashback, Rabatt auf den Ausgabeaufschlag',
        description:
          'Kaufen Sie Fonds ohne Ausgabeaufschlag und erhalten die Verwaltungsvergütung als Cashback erstattet. Dadurch steigt Ihre Fonds-Rendite erheblich.',
        pageTopics: 'Fondssparen, Fondssparer, Fonds-Cashback',
        simpleCalculator: {
          title: 'Calculate your cashback:',
        },
        benefits: {
          left: 'Free',
          right: 'Cancel any time',
          rankTitle: 'satisfied customers',
          rankSubTitle: 'according to Proven Expert',
        },
      },
      cashbackCalculator: {
        navigation: {
          previous: 'Back',
        },
        title: 'Fondsdiscount-Rechner: Hier Vorteil berechnen',
        keywords:
          'Fonds-Provisionen, Bestandsprovision, Fondssparen, Fondssparer, Fonds-Cashback, Rabatt auf den Ausgabeaufschlag',
        description:
          'Berechnen Sie die Kosten Ihrer Investment-Fonds. Steigern Sie Ihre Fonds-Rendite indem Sie sich Ihre Provisionen mit Rentablo Cashback zurückholen.',
        pageTopics: 'Fonds-Provisionen, Fondssparer, Fonds-Cashback',
        subheading:
          'Fairness redefined: Rentablo provides you with a detailed overview on fund commissions. Calculate your personal cashback-advantage and give a boost to your return on investment!',
        simple: {
          amount: 'Amount',
          investedAmount: 'Amount currently invested in funds (no ETF)',
          plannedInvestment: 'Planned investment',
          year: 'Year',
          years: 'Years',
          oneTimeCommission: {
            label: 'Agio discount',
          },
          cashback: {
            label: 'Cashback',
          },
        },
        detailed: {
          calculator: {
            clearAll: 'Clear all',
            chartHeading: 'Yearly commission: which bank pays how much?',
          },
          partnerBankSelector: {
            label: 'Your depot bank',
            otherBank: 'Other bank',
          },
          funds: {
            label: 'Fund selection',
            addFund: 'Select first fund',
          },
          fundModal: {
            labelAdd: 'Add fund',
            labelEdit: 'Edit fund',
            apply: 'Add to list',
            cancel: 'Cancel',
          },
          fundAutocomplete: {
            addMore: '+ Add another fund',
            placeholder: 'Fund name or ISIN',
            mostUsed: 'Popular funds',
            others: 'Your search',
          },
          fundInput: {
            amount: 'Invested amount',
            agio: 'Agio',
            portfolioCommission: 'Portfolio Commission p.a.',
            savingsPlanTooltip: 'Savings plan starting from {{from}} at {{bank}}',
            agioDiscount: '{{percent}} agio discount',
            savingsPlan: 'SP',
            vl: 'VL',
            notAvailableAt: 'Fund not available at {{bank}}',
            limitedAgioDiscount: 'Agio discount not possible or only partially possible',
          },
          aggregatedCommissionBox: {
            calculateCashback: '... or calculate exact cashback now',
            refundableAmount: 'Cashback-refundable amount',
            totalAgio: 'Your Agio discount',
            depotComplete: 'All depot positions entered?',
          },
          advantageBox: {
            rentabloAdvantage: 'Your rentablo advantage',
            yourCashback: 'Your cashback will be',
            yourAgioDiscount: 'Your Agio discount',
            yourCost: 'Your cost',
            applyButton: 'Apply for Funds-Cashback now',
          },
          cashbackAreaChart: {
            chartHeading: 'Cashback estimate for {{depotVolume}} depot volume',
            rentabloFee: 'Max. {{fee}} for Rentablo per month',
            rentabloFeeXs: 'Max. {{fee}} for Rentablo',
            rentabloFeeCB50: 'For Rentablo',
            cashbackLabel: 'Your funds-cashback',
            depotVolume: 'Depot volume',
            portfolioCommission: 'Portfolio Commission',
            fee: 'For Rentablo',
            yourCashback: 'Your Cashback',
            perMonth: 'monthly',
          },
          cashbackDisclaimer: {
            label:
              'Information is based on original commission statements of our partner banks; all data given without warranty',
            showChart: 'View banks comparison graph',
          },
        },
        getCashbackNowButton: {
          label: 'Get your Funds-Cashback now',
        },
      },
      cashbackIntro: {},
      jobs: {
        title: 'Komm ins wealthAPI Team',
        description: 'Aktuell Stellenangebote der wealthAPI GmbH',
        keywords: 'Developer, Frontend Engineer, Backend Engineer, React, Rails',
        pageTopics: 'We are hiring - Jobs for software engineers at wealthAPI GmbH, Berlin',
      },
      header: {
        financeManager: 'Finance Manager',
        cashback: 'Funds Cashback',
        logIn: 'Log In',
        logOut: 'Log Out',
        signUp: 'Sign Up',
        dashboard: 'Dashboard',
        forum: 'Forum & FAQ',
        manageMyBookings: 'Manage My Bookings',
      },
      navigation: {
        cancel: 'Cancel',
        previous: 'Back',
        next: 'Next',
      },
      footer: {
        column1: {
          title: 'About us',
          items: [
            // {
            //   href: 'https://wealthapi.eu/ueber-uns/',
            //   title: 'About us',
            // },
            {
              href: 'https://wealthapi.eu/karriere/',
              title: 'Career',
            },
            {
              href: 'https://www.financequality.net/partnerprogramme/rentablo-fond-cashback/',
              title: 'Partner program',
            },
            {
              href: 'https://wealthapi.eu',
              title: 'Commercial customers',
            },
          ],
        },
        column2: {
          title: 'Funds-Cashback',
          items: [
            {
              href: 'https://rentablo.de/cashback',
              title: 'Fonds-Cashback',
            },
            {
              href: 'https://rentablo.de#cashback-calculator',
              title: 'Funds Commission Calculator',
            },
          ],
        },
        column3: {
          title: 'Portfolio Software',
          items: [
            {
              href: 'https://rentablo.de/software-depotverwaltung',
              title: 'Portfolio Management Software',
            },
            {
              href: 'https://rentablo.de/portfolio-performance',
              title: 'Portfolio Performance',
            },
            {
              href: 'https://rentablo.de/portfolio-optimierung',
              title: 'Optimize Portfolio',
            },
          ],
        },
        column4: {
          title: 'Finance Tools and more',
          items: [
            {
              href: 'https://forum.rentablo.de/',
              title: 'Forum and FAQ',
            },
            {
              href: 'https://blog.rentablo.de/',
              title: 'Finance Blog',
            },
            {
              href: 'https://rentablo.de/kostenloser-finanzmanager-online',
              title: 'Free finance manager',
            },
            {
              href: 'https://rentablo.de/elektronisches-haushaltsbuch',
              title: 'Electronic household book',
            },
            {
              href: 'https://blog.rentablo.de/vergleichsrechner-und-finanztools/',
              title: 'Save and compare',
            },
          ],
        },
        subFooterItems: [
          {
            href: 'https://rentablo.de/v2/impressum',
            title: 'Impressum',
          },
          {
            href: 'https://rentablo.de/v2/privacy',
            title: 'Data protection',
          },
          {
            href: 'https://rentablo.de/v2/agb',
            title: 'Terms',
          },
          {
            href: 'https://rentablo.de/v2/disclaimer',
            title: 'Disclaimer',
          },
        ],
      },

      existingSubscriptionsAlert: {
        message: 'You already have one subscription.',
        message_plural: 'You already have {{count}} subscriptions.',
        manageLink: 'Manage',
      },
      bankOverview: {
        blockTitle: 'Comparison of conditions of our partner banks:',
        testWinner: 'Test winner',
        depotFee: 'Deposit fee',
        refundPortfolioCommission: 'Commission refund',
        discountOneTimeCommission: 'Discount on one time commission',
        availableFunds: 'Available funds',
        savingsPlanPrice: 'Savings plan price',
        averageCashback: 'Average Cashback p.a.',
        rating: 'Our rating',
        costWarning: 'Warning price!',
        card: {
          more: 'Learn more',
          rank: 'Our rating:',
        },
        comdirect: {
          availableFunds: 'over 24.000',
        },
        augsburgerAktienbank: {
          availableFunds: 'over 12.000',
        },
        dab: {
          availableFunds: 'over 13.000',
        },
        ebase: {
          availableFunds: 'over 6.900',
        },
        fondsdepotBank: { availableFunds: 'over 7.000', depotFee: '0€ bis 30€' },
        fbb: { availableFunds: 'over 7.000', depotFee: '0-45€' },
        metzler: { availableFunds: 'over 7.500' },
        morgenFund: {
          availableFunds: '0,00 - 50,00 €',
          depotFee: '0€ to 50€',
          headerTitle: 'Ehemals DWS Depot',
        },
      },
      products: {
        title: 'Rentablo Portfoliomanager - Premium Pakete',
        tableTitle: 'Detailübersicht',
        free: {
          subheader: 'Kostenlose Portfolio-Verwaltung',
          features: [
            '1 Bankverbindung',
            '3 Jahre historische Daten',
            'Dividendenplaner',
            'Support über das Rentablo Forum',
          ],
        },
        plus: {
          subheader: 'für Investoren im Vermögensaufbau',
          features: [
            'alle <strong>Basis-Funktionen inklusive</strong>',
            '<strong>5</strong> Bankverbindungen',
            '<strong>6 Jahre</strong> historische Daten',
            '<strong>Intraday</strong> Kurse',
            'E-Mail Support innerhalb von 5 Tagen',
            'Fremdwährungen',
          ],
        },
        pro: {
          subheader: 'für Investoren, die ihr Vermögen selbst professionell verwalten',
          features: [
            'alle <strong>PLUS Funktionen inklusive</strong>',
            '<strong>unbegrenzt</strong> viele Bankverbindungen',
            '<strong>Aktien</strong>-Fundamentaldaten',
            '<strong>10 Jahre</strong> historische Daten',
            '<strong>Telefon-Support</strong>',
          ],
          comingSoonFeatures: [
            '<strong>Fonds</strong>-Fundamentaldaten',
            '<strong>Reports</strong> und Export-optionen',
            'Rendite-Risiko-<strong>Simulator</strong>',
          ],
        },
        section: {
          depots: 'Depots',
          investments: 'Investments',
          comingSoon: 'In Kürze verfügbar',
        },
        features: {
          bankConnection: 'Bankverbindungen',
          historyData: 'Historische Daten',
          support: 'Support',
          financialGoals: 'Finanzziele',
          crypto: 'Kryptowährungen',
          foreignCurrencies: 'Fremdwährungen',
          goldAndMaterialAssets: 'Edelmetalle und Sachwerte',
          dividends: 'Dividendenplaner',
          intraday: 'Intraday Kurse',
          fundamentalStockData: 'Aktien-Fundamentaldaten',
          fundamentalFundsData: 'Fonds-Fundamentaldaten',
          reports: 'Reports und Export-optionen',
          taxSimulation: 'Steuer-Simulator',
          riskSimulation: 'Rendite-Risiko-Simulator',
          underDevelopment: 'in Entwicklung',
        },
        historyData: {
          free: '3 Jahre',
          plus: '6 Jahre',
          pro: '10 Jahre',
        },
        support: {
          free: 'Rentablo Forum',
          plus: 'Email',
          pro: 'Email und Telefon',
        },
        priceDesc: {
          discountTime: 'Einführungspreis bis',
          line1: 'pro Monat, inkl. MwSt.',
          line2yearly: 'bei jährlicher Zahlweise',
          line2monthly: 'bei monatlicher Zahlweise',
        },
        register: 'Registrieren',
        subscribe: '30 Tage kostenlos testen',
        subscribeSmall: 'Jetzt testen',
        moreDetails: 'Alle Funktionen im Detail',
        moreDetailsTableName: '{{name}} Funktionen',
        monthlySwitchTitle: 'monatliche zahlweise',
        currentSubscription: 'IHR PLAN',
        changeSubscription: 'Plan wählen',
        sevenDaysNotificationMessage:
          '* Keine Sorge: Wir erinnern Sie 7 Tage vor Ablauf des Testzeitraums.',
        yourPlan: 'Ihr Plan',
        detailsAndSettingsLink: 'Details und Einstellungen',
        discountStamp: {
          shortTime: 'Nur für kurze Zeit:',
          discount: 'Rabatt',
        },
      },
      banksComparisonTable: {
        accountManagement: 'Depotführung p.a.',
        availableFunds: 'Verfügbare Fonds',
        numberOfFunds: 'Anzahl Fonds',
        numberOfFundsSavingPlan: 'Davon Sparplanfähig',
        fundConditions: 'Konditionen Fonds',
        fundPurchasePrice: 'Fondskauf',
        fundSavingsPlanPrice: 'Fonds-Sparplan',
        commissionReimbursement: 'Vergleichswerte Provisions-Rückerstattung',
        averageReimbursement: 'Durchschnittliche Rückerstattung p.a.',
        ETFConditions: 'Konditionen ETF',
        ETFPurchasePrice: 'ETF-Kauf',
        ETFSavingsPlanPrice: 'ETF-Sparplan',
        stockExchangeTransactions: 'Konditionen Börsengeschäfte',
        exchangeTrading: 'Börsenhandel möglich',
        securitiesTrading: 'Handel Wertpapiere',
        drawings: 'Zeichnungen',
        capitalAccumulationBenefits: 'Vermögenswirksame Leistungen',
        VLSavingsPlan: 'VL Sparplan möglich',
        others: 'Sonstiges',
        service: 'Service / Erreichbarkeit',
        onlineBanking: 'Online-Banking',
        financeManager: 'Integration Portfoliomanager',
        rating: 'Unser Rating',
      },
    },
  },
  de: {
    translation: {
      components: {
        isinSearch: {
          placeholder: 'ISIN / WKN / Fond Name ...',
          applyButton: 'Rabatt abfragen',
        },
        advantagesTable: {
          headers: {
            first: 'Ihr Vorteil',
            second: 'als Fondsdiscounter 2.0',
            third: 'Fondsdiscounter 1.0',
          },
        },
        layouts: {
          redesign: {
            header: {
              actions: {
                fundDiscount: 'Fondsdiscount',
                yourDiscount: 'Ihre Vorteile',
                conditions: 'Konditionen',
                depotManagerApp: 'Depotverwaltungs-App',
                aboutUs: 'Über uns',
                knowledge: 'Wissen',
                login: 'Login',
                becomeCustomer: 'Kunde werden',
                inApp: 'Home',
                cashback: 'Cashback beantragen',
                faqs: 'FAQs',
                forum: 'Forum',
                blog: 'Blog',
                support: 'Support',
              },
            },
            footer: {
              copyright: 'Rentablo GmbH',
              column1: {
                title: 'Unternehmen',
                items: [
                  // {
                  //   href: 'https://wealthapi.eu/ueber-uns/',
                  //   title: 'Über uns',
                  // },
                  {
                    href: 'https://wealthapi.eu/karriere/',
                    title: 'Jobs & Karriere',
                  },
                  {
                    href: 'https://www.financequality.net/partnerprogramme/rentablo-fond-cashback/',
                    title: 'Partnerprogramm',
                  },
                  {
                    href: 'https://wealthapi.eu',
                    title: 'Geschäftskunden',
                  },
                ],
              },
              column2: {
                title: 'Features',
                items: [
                  {
                    href: 'https://rentablo.de/software-depotverwaltung',
                    title: 'Software Depotverwaltung',
                  },
                  {
                    href: 'https://rentablo.de/portfolio-performance',
                    title: 'Portfolio Performance',
                  },
                  {
                    href: 'https://rentablo.de/portfolio-optimierung',
                    title: 'Portfolio Optimierung',
                  },
                  {
                    href: 'https://rentablo.de/kostenloser-finanzmanager-online',
                    title: 'Kostenloser Portfoliomanager',
                  },
                  {
                    href: 'https://rentablo.de/elektronisches-haushaltsbuch',
                    title: 'Elektronisches Haushaltsbuch',
                  },
                ],
              },
              column3: {
                title: 'Wissen & Finanztools',
                items: [
                  {
                    href: 'https://forum.rentablo.de/',
                    title: 'Forum & FAQ',
                  },
                  {
                    href: 'https://blog.rentablo.de/',
                    title: 'Finanzblog',
                  },
                  {
                    href: 'https://rentablo.de#cashback-calculator',
                    title: 'Fondsdiscount-Rechner',
                  },
                ],
              },
              column4: {
                title: 'Finanztools & Wissen',
                items: [
                  {
                    href: 'https://forum.rentablo.de/',
                    title: 'Forum & FAQ',
                  },
                  {
                    href: 'https://blog.rentablo.de/',
                    title: 'Finanzblog',
                  },
                  {
                    href: 'https://rentablo.de/kostenloser-finanzmanager-online',
                    title: 'Kostenloser Portfoliomanager',
                  },
                  {
                    href: 'https://rentablo.de/elektronisches-haushaltsbuch',
                    title: 'Elektronisches Haushaltsbuch',
                  },
                  {
                    href: 'https://blog.rentablo.de/vergleichsrechner-und-finanztools/',
                    title: 'Vergleichsrechner',
                  },
                ],
              },
              subFooterItems: [
                {
                  href: 'https://rentablo.de/v2/impressum',
                  title: 'Impressum',
                },
                {
                  href: 'https://rentablo.de/v2/privacy',
                  title: 'Datenschutz',
                },
                {
                  href: 'https://rentablo.de/v2/agb',
                  title: 'AGBs',
                },
                {
                  href: 'https://rentablo.de/v2/disclaimer',
                  title: 'Haftungsausschluss',
                },
              ],
            },
          },
        },
      },
      ieAlert: {
        title:
          'Sie verwenden den Microsoft Internet Explorer. Dieser Browser wird nicht mehr unterstützt.',
        description:
          'Diese Seite wird daher ggf. nicht korrekt angezeigt. Bitte verwenden Sie Google Chrome, Opera, Firefox or Edge als moderne Alternative.',
      },
      structuralData: {
        webSite: {
          name: 'Rentablo - Fondsdiscount 2.0 und Portfoliomanager',
          description:
            'Rentablo macht Fondssparen rentabler - inklusive Deutschlands bestem Portfoliomanager',
        },
        headerImage: { caption: 'Rentablo - macht Fondssparen rentabler' },
      },
      customerService: {
        phoneLink: '03071533009',
        phone: '030 - 7153 3009',
        email: 'info@rentablo.de',
        supportTime: 'Montag bis Freitag von 9 bis 19 Uhr erreichbar',
      },
      landingMain: {
        claim: 'Rentablo - macht Fondssparen rentabler',
        title: 'Fondsdiscount 2.0: Fonds ohne Ausgabeaufschlag PLUS Cashback',
        keywords: 'Fondsdiscount, Fondssupermarkt, Fondsvermittler, Investmentfonds',
        description:
          'Doppelt sparen bei Investmentfonds: "Sehr gut” im Fondsvermittler Test von Börse Online, bekannt aus Stiftung Warentest.',
        pageTopics:
          'Fondssparen, Fonds-Cashback, Software Depotverwaltung, Finanzmanager, Portfoliomanager, Portfolio Performance',
        mainScreen: {
          title: {
            first: '<mainText>Der Fondsdiscounter</mainText>',
            second: 'mit Doppelersparnis',
          },
          startNow: 'Jetzt starten',
          learnMore: 'Jetzt Cashback berechnen',
        },
        headerInfo: {
          funds: '25.000+ Fonds',
          partnerBanks: '7 Partnerbanken',
          cost: '0 Euro Kosten',
          simpleAndFair: 'Einfach, fair und sicher',
        },
        cashbackCalculator: {
          title: '<mainText>Fondsdiscount-Rechner</mainText>',
          subTitle: '<greyText>Ihren Vorteil berechnen</greyText>',
        },
        factsBar: {
          funds: '24.000 Fonds',
          partnerBanks: '8 Partnerbanken',
          provenExpert: '4.8 Sterne (Proven Expert)',
          cost: '0 Euro Kosten',
          simpleAndFair: 'Einfach, fair und sicher',
        },
        yourAdvantages: {
          title: 'Ihre <mainText>exklusiven Vorteile</mainText> mit Rentablo',
          subtitle:
            'Wir bieten Ihnen nicht nur Agio-Rabatt und Cashback, sondern auch absolute Transparenz, höchste Sicherheit, riesige Zeitersparnis und einen maximalen Überblick über Ihre Investments',
          checklist: {
            title: 'Ihre Vorteils-Checkliste',
            button: 'Jetzt Fonds-Cashback beantragen',
          },
        },
        checkmarksList: {
          first: 'Bis 100% Agio Rabatt',
          second: 'Bis 100% Cashback',
          third: 'Garantiert kostenfrei',
        },
        cashbackIntro: {
          title: 'Doppelt sparen bei Investmentfonds',
          subTitle: 'Fondsdiscount 2.0',
          startNow: 'Jetzt Starten',
          claim1: 'Fonds ohne Ausgabeaufschlag kaufen',
          claim2: 'Zusätzliche Gutschrift aus den internen Fondsprovisionen jedes Quartal',
          learnMore: 'Mehr erfahren',
          toPortfolioManager: 'zum Portfolio-Manager 2.0',
        },
        financeManagerIntro: {
          title: 'Depot-Performance einfach messen',
          subTitle: 'Portfoliomanager 2.0',
          startNow: 'Jetzt Starten',
          claim1: 'Portfolio-Performance bankübergreifend',
          claim2: 'automatisierter Datenimport',
          learnMore: 'Mehr erfahren',
        },
        fundsCashbackBox: {
          heading: 'Fonds-Cashback',
          weGetYourFeesBack: 'Wir holen Ihre Gebühren zurück!',
          discountOnAgio: '<0>100 % Rabatt</0> auf den Ausgabeaufschlag',
          discountOnPortfolioCommission:
            'Auszahlung Bestandsprovision - auch für bereits gekaufte Fonds',
          alsoForCompanyDepots: 'Auch für <1>Firmen und Stiftungen<1>',
          bookNow: 'Hier Auszahlung sichern',
          bookNowXs: 'Auszahlung sichern',
          forFree: 'Garantiert kostenfrei',
          calculateNow: 'Oder jetzt Cashback berechnen',
        },
        financeManagerBox: {
          including: 'inklusive',
          germaniesBestFinanceManager: 'Deutschlands bester Portfoliomanager',
          financeManager: 'Software zur Vermögensverwaltung',
          manageAllFinances: 'alle Finanzen im Griff',
          learnMore: 'Mehr erfahren',
        },
        expertSupportBox: {
          including: 'inklusive',
          expertSupport: 'Experten Support',
          realPeople: 'echte Menschen',
          realKnowHow: 'echtes Know-How',
        },
        fundsDiscounter: {
          title: 'Der Fonds-Discounter 2.0',
          titleSmall: 'Rentablo hat bereits',
          subTitle: 'bereits über',
          message: '3.000.000 € Cashback',
          subMessage: 'an unsere Kunden ausgezahlt',
          subMessageSmall: 'an Kunden ausgezahlt',
        },
        wellKnown: {
          title: 'Bekannt aus',
          imgTitle: 'Bekannt aus',
        },
        partnerBanks: {
          title: 'Die Rentablo-Partner-Banken',
          subtitle: 'Zum Vergleich auf Bank-Icon klicken',
        },
        simpleCalculator: {
          title: 'So viel bringt Ihnen Cashback:',
        },
        detailCalculator: {
          title: 'Hier Vorteil berechnen:',
          subtitle:
            'Gut zu wissen: Selbst wenn Sie die Fondsanteile bereits gekauft haben, können Sie immer noch an der laufenden Bestandsprovision (Cashback) partizipieren, indem Sie zu Rentablo wechseln.',
        },
        faq: {
          title: 'FAQs',
          applyButton: 'Jetzt Fonds-Cashback beantragen',
          questions: [
            {
              question: 'Was macht Rentablo?',
              answer:
                'Rentablo ist ein Fondsdiscounter, aber in der Version 2.0. Wie bei anderen Fondsdiscountern können Sie mit Rentablo fast alle Fonds ohne Ausgabeaufschlag kaufen. Rentablo geht beim Cashback noch einen Schritt weiter: Als Rentablo-Kunde erhalten Sie bis zu 100 Prozent der Bestandsprovision eines Fonds erstattet, und zwar quartalsweise.  Wie viel, hängt vom Fonds und der jeweiligen Depotbank ab. Sie können das direkt hier auf der Seite ausrechnen.',
            },
            {
              question: 'Wie buche ich Cashback?',
              answer:
                'Sie können Ihr Fondscashback ganz einfach auf dieser Webpage buchen. Klicken Sie hierfür auf den Button “Hier Fonds-Cashback beantragen” und folgenden den Fragen in der Antragsstrecke. Am Ende erhalten Sie eine PDF, welche Sie bitte ausdrucken, unterschreiben und  an uns senden.',
              forumLinkText: 'So beantrage ich Cashback',
              forumLinkHref:
                'https://forum.rentablo.de/t/rentablo-cashback-buchen-beantragen-wie-geht-das/928',
            },
            {
              question: 'Ist Rentablo eine Bank?',
              answer:
                'Nein, Rentablo ist keine Bank, aber von der BaFin (Aufsicht) kontrolliert. Im Gegensatz zu einer Bank jedoch, verkaufen wir Ihnen nichts. Rentablo ist ein Service für Selbstentscheider bei Finanzangelegenheiten. Sie handeln und entscheiden immer selbst. Sie erhalten weder Anlageberatung noch irgendwelche "heißen Tipps", was Sie kaufen sollten. Via Rentablo Cashback holen Sie einen großen Teil der Fondskosten zurück, die sonst bei der Bank verbleiben oder von Ihrem Finanzvermittler einbehalten werden. Mit dem kostenlosen Rentablo Portfoliomanager können Sie zudem bequem Ihre Finanzen verwalten, analysieren und optimieren.',
            },
            {
              question: 'Wo gehen die Fondsanteile hin?',
              answer:
                'Ihre Fondsanteile sind und bleiben da, wo sie immer sind: bei Ihrer Depotbank. Rentablo darf weder Ihre Fondsanteile verkaufen noch Fondsanteile für Sie kaufen. Wie gesagt: Das ist ein Service für Selbstentscheider. Bei Ihrer bisherigen oder neuen Depotbank gibt es nur einen Betreuerwechsel. Rentablo wird als Ihr Betreuer eingetragen und verzichtet sofort auf Abschlussprovisionen (Ausgabeaufschläge) und schüttet Bestandsprovisionen als Cashback zu den hervorragenden Rentablo-Konditionen an Sie aus.',
            },
            {
              question: 'Was muss ich dafür tun?',
              answer:
                'Nicht viel. Auf dieser Seite sehen Sie die acht onvista-Cashback Partnerbanken. Wenn Sie bereits Fondsanteile bei einer dieser Depotbanken verwahren, ist es besonders einfach. Sie beantragen das onvista-Cashback und damit lediglich einen Betreuerwechsel. Das dauert nur wenige Minuten. Wenn Ihre Depotbank hier nicht aufgeführt sein sollte, ist es notwendig, ein neues Depotkonto mit Rentablo als Betreuer bei einer der sieben Partnerbanken zu eröffnen. Das geht ebenfalls innerhalb weniger Minuten.',
            },
            {
              question: 'Was kostet mich Rentablo?',
              answer:
                'Cashback wird Sie niemals etwas kosten - Sie werden immer ein Plus erzielen (auch wenn wir etwas daran verdienen). Ehrenwort! Wovon wir leben? Von einem Teil der Bestandsprovision, der für den Service bei uns bleibt. Cashback erhalten Sie bereits vom ersten investierten Euro an. Es gibt keine Mindestdepotgröße. Sie erhalten von Anfang an 50 % der an uns ausgeschütteten Bestandsprovision (Cashback-50, lt. Stiftung Warentest bis zu einer Depotgröße von ca. 75.000 €). Unser Anteil bleibt jedoch bei 15 €/Monat stehen. Darüber hinaus gehende Beträge erstatten wir Ihnen zu 100% (Cashback-100).',
            },
          ],
        },
        counters: {
          title: 'Unsere Kunden haben bisher...',
          subtitleLeft: 'Ausgabeaufschläge gespart',
          subtitleRight: 'Cashback erhalten',
        },
        callToAction: {
          title: 'Jetzt rentabler Fondssparen!',
          subTitleType1: 'Holen Sie sich Ihr Geld zurück und sichern Sie Ihre Cashback Prämie!',
          subTitleType2: 'Schnell, einfach und sicher mehr Rendite!',
          applyButton: 'Hier Fonds-Cashback beantragen',
          applyButtonXs: 'Hier beantragen',
        },
        testimonials: {
          clientsTitle: 'Das sagen Kunden',
          expertsTitle: 'Das sagen Finanzexperten',
          bothTitle: 'Das sagen Kunden und Finanzexperten',
          willkomm: {
            name: 'Vincent Willkomm',
            description: 'Finanzblogger, Hamburg',
            text: 'Um die Kosten von aktiven Investmentfonds in Richtung ETF-Niveau zu drücken, nutze ich das Rentablo Cashback. Das garantiert die höchste Rückerstattung von Provisionen.',
          },
          siebers: {
            name: 'Torsten Siebers',
            description: 'Unternehmensberater, Hamburg',
            text: 'Als Nutzer bin ich immer wieder begeistert, mit welchem Enthusiasmus sich das Team von Rentablo dem Verbraucherschutz widmet und mit Rentablo Cashback auch umsetzt.',
          },
          bukow: {
            name: 'E. Bukow',
            description: 'kfm. Angestellte, Düsseldorf',
            text: 'Besten Dank Rentablo! Die Abrechnungen vom Cashback kommen pünktlich und werden auf den Cent genau ausgewiesen.',
          },
          konetzke: {
            name: 'Toralf Konetzke',
            description: 'Unternehmer und Profisportler, Cottbus',
            text: 'Mit dem Cashback habt Ihr bei mir einen Elfmeter verwandelt. Kosten runter und Rendite hoch - perfekt! Danke und weiter so!',
          },
          ingeborg: {
            name: 'Ingeborg K.',
            description: 'Pensionärin, Nürnberg',
            text: 'Ich nutze Rentablo Cashback bereits seit Januar 2018 und freue mich seitdem über pünktliche Auszahlungen von mehr als 1.200 Euro im Quartal.',
          },
          handelsblatt: {
            title: 'Handelsblatt',
            text: '',
          },
          ard: {
            title: 'börse ARD',
            text: '',
          },
          br: {
            title: 'BR',
            text: '',
          },
          fondsProfessionell: {
            title: 'Fonds-Professionell',
            text: '',
          },
          tagesschau: {
            title: 'Tagesschau',
            text: '',
          },
          sueddeutsche: {
            title: 'Süddeutsche Zeitung',
            text: 'Wer in Fonds anlegt, muss auf die Kosten achten. Jetzt werben Anbieter mit der Erstattung von Provisionen.',
          },
          boersede: {
            title: 'boerse.de',
            text: '',
          },
          welt: {
            title: 'Die Welt, Future of Finance',
            text: 'Wer sein Vermögen in Zeiten des Nullzinses erhalten will, muss es diversifizieren. Hierzu bieten sich Investment-Fonds an - leider oft zu überhöhten Gebühren. Mit seinen Cashback-Modellen bietet das FinTech Rentablo Anlegern erstmals die Möglichkeit, sich bis zu 100% der Provisionen zurückzuholen.',
          },
          wiwo: {
            title: 'Wirtschaftswoche',
            text: '...das Fintech Rentablo, “eine Mischung aus Aldi und Deutsche Bank”.',
          },
          finanztest: {
            title: 'Stiftung Warentest / Finanztest',
            text: 'Das besondere Angebot von rentablo.de: Kunden, die dort das Depotmodell "Cashback-100" buchen, erhalten gezahlte Bestands­provisionen und Ausgabe­aufschläge in der Regel voll­ständig zurück.',
          },
          focusMoney: {
            title: 'Focus Money, Fairstes Produktangebot',
            text: 'Savedo und Rentablo bieten das beste Preis-Leistungs-Verhältnis.',
          },
          versicherungsmagazin: {
            title: 'Versicherungsmagazin, Fintech unter der Lupe',
            text: 'Rentablo - Leidenschaftliche Investoren mit Sinn fürs Kollektiv',
          },
        },
      },
      redesignMainPage: {
        cashbackIntro: {
          title: 'Doppelt sparen bei Investmentfonds.',
          subTitle: 'Fondsdiscount 2.0',
          startNow: 'Jetzt Starten',
          description:
            'Fonds ohne Ausgabeaufschlag kaufen.  Zusätzliche weitere Gutschriften als Fonds-Cashback erhalten. Quartalsweise.',
          learnMore: 'Mehr erfahren',
          toPortfolioManager: 'zum Portfolio-Manager 2.0',
          imgAlt: 'Trust logo',
        },
        financeManagerIntro: {
          title: 'Depot Performance. Einfach und messbar.',
          subTitle: 'Depotverwaltungs-App',
          startNow: 'Jetzt Starten',
          description:
            'Alles im Blick. Bankübergreifend Konten und Depots verwalten und analysieren. Automatisierter Datenimport.',
          learnMore: 'Mehr erfahren',
          imgAlt: 'Bank awards 2021',
        },
        preview: {
          videoBlock: {
            tag: 'Rentablo fonds-cashback',
            title: 'Mit uns profitieren Sie von der ersten Sekunde an.',
            subtitle: 'Sie müssen nie etwas zahlen – garantiert kostenfrei!',
            action: 'Jetzt starten',
            link: 'Jetzt Cashback berechnen',
          },
          application: {
            header: {
              title: '<whiteText>Kostenlos inklusive.</whiteText>',
              title2: '<greyText>Die Rentablo Depotverwaltungs-App.</greyText>',
            },

            finance: {
              subtitle: 'Finanzmanager',
              title: 'Ihre gesamten Finanzen im Blick',
            },
            oneClick: {
              subtitle: 'One-click Import',
              title: 'Nahtloser Import Ihrer Konten und Depots',
            },
            evaluate: {
              subtitle: 'Auswerten',
              title: 'Risiken erkennen',
            },
            setGoals: {
              subtitle: 'Ziele setzen',
              title: 'Wunschrendite erzielen',
            },
            moreFinancial: {
              text: 'Mehr finanzieller Überblick mit der Rentablo Depotverwaltungs-App',
              btn: 'Jetzt testen',
            },
          },
        },
        wellKnown: {
          title: 'Bekannt aus',
        },
        testimonials: {
          welt: {
            title: 'Die Welt, Future of Finance',
            text: 'Wer sein Vermögen in Zeiten des Nullzinses erhalten will, muss es diversifizieren. Hierzu bieten sich Investment-Fonds an - leider oft zu überhöhten Gebühren. Mit seinen Cashback-Modellen bietet das FinTech Rentablo Anlegern erstmals die Möglichkeit, sich bis zu 100% der Provisionen zurückzuholen.',
          },
          wiwo: {
            title: 'Wirtschaftswoche',
            text: '...das Fintech Rentablo, “eine Mischung aus Aldi und Deutsche Bank”.',
          },
          finanztest: {
            title: 'Stiftung Warentest / Finanztest',
            text: 'Das besondere Angebot von rentablo.de: Kunden, die dort das Depotmodell "Cashback-100" buchen, erhalten gezahlte Bestands­provisionen und Ausgabe­aufschläge in der Regel voll­ständig zurück.',
          },
          focusMoney: {
            title: 'Focus Money, Fairstes Produktangebot',
            text: 'Savedo und Rentablo bieten das beste Preis-Leistungs-Verhältnis.',
          },
        },
        experienced: {
          logoAlt: 'Rentablo',
          title: 'Etabliert & Erfahren.',
          subtitle: 'Rentablo ist schon lange am Markt – uns gibt es bereits seit 2014.',
        },
      },
      redesignCashbackPage: {
        cashbackForFunds: {
          subTitle: 'Cashback für Fonds',
          title: 'Fondsdiscounter mit Doppelersparnis',
          learnMore: 'Jetzt Cashback berechnen',
          startNow: 'Jetzt buchen',
        },
        cashbackCalculator: {
          title: 'Cashback Rechner',
        },
        fundDiscount: {
          title: 'So funktioniert unser <mainText>Fondsdiscount</mainText>',
          description:
            'Rentablo ist ein Fondsdiscounter, und zwar der Version 2.0: Wie bei anderen Fondsdiscount-Anbietern kaufen Sie Tausende Fonds ohne Ausgabeaufschlag, also ohne Abschlussprovisionen. Zusätzlich schüttet Rentablo laufende Bestandsprovisionen an Sie aus. Sie sparen mit uns doppelt - deshalb Fondsdiscount 2.0.',
          oneStep: {
            title: 'Berechnen Sie sofort, wie viel Sie bei der Fondsanlage sparen können',
            imageTitle: 'Die Ersparnis beim Fondsdiscount 2.0',
            description:
              'Der maximale Rabatt beim Ausgabeaufschlag sowie die Ausschüttung von Bestandsprovision (Cashback) hängen ab von der Depotbank und den Fonds, die Sie gewählt haben. Geben Sie im Rechner Ihre Fonds sowie die jeweilige Anlagesumme an. Sie erhalten sofort das Ergebnis, wie groß Ihr finanzieller Vorteil mit Fondsdiscount von Rentablo ist.<separator /><separator />Sehr wichtig: Sie erhalten Cashback auch für Fondsanteile, die sich bereits in Ihrem Depots befinden!',
          },
          custodianBank: {
            title: 'Doppelte Ersparnis: einfach und sicher',
            imageTitle: 'Unsere Auszahlungen als Fondsdiscounter',
            description:
              'Um als Fondsanleger von den Rentablo-Konditionen profitieren zu können, benötigen Sie lediglich ein Wertpapier-Depot bei einer unserer sechs günstigen Partnerbanken. Vielleicht haben Sie dort bereits ein Depot, dann ist lediglich ein Wechsel des Fondsvermittlers in den Depotdaten notwendig. Oder Sie eröffnen ganz einfach ein neues Depot mit Rentablo als Fondsvermittler. Das dauert nur etwa fünf Minuten.<separator /><separator />Unser Fondsdiscount ist sicher: Ihr Depot und die darin enthaltenen Wertpapiere liegen immer bei der Depotbank. Als Ihr Fondsvermittler haben und wollen keinen Zugriff auf Ihre Fondsanteile, noch können wir Aufträge für Sie ausführen.',
          },
          applyingCashback: {
            title: {
              firstLine: 'Weniger Kosten.',
              secondLine: 'Mehr Rendite.',
            },
            imageTitle: 'So gross ist der Mehrertrag mit Fondsdiscount (Rechenbeispiel)',
            description:
              'Mit Cashback steigern Sie Ihre Fondsrendite beträchtlich<separator /><separator />Fondsdiscount mit 100 % Rabatt auf den Ausgabeaufschlag beim Fondskauf ist bereits eine große, einmalige Ersparnis. Das Cashback beim Rentablo Fondsdiscount 2.0 kommt dauerhaft hinzu, so lange Sie die Fondsanteile halten. Die Ausschüttung wird jedes Quartal neu berechnet und ausgeschüttet.<separator /><separator />Steigt Ihr Fondsvolumen durch Kurszuwächse, steigt auch Ihr Cashback! Im Schnitt betragen die Bestandsprovisionen 0,56 % p.a. des Fondsvolumens - die Ausschüttung wird zu Ihrer Zusatzrendite beim Fondssparen.',
          },
          cashbackNumber: {
            currency: 'Mio+ €',
            cashback: 'Cashback',
            title: 'bereits ausgezahlt',
          },
        },
        cashbackStepper: {
          title:
            'Cashback ist <mainText>kostenfrei</mainText> und <mainText>einfach</mainText> zu beantragen',
          subtitle:
            'Wovon wir leben? Von einem Teil der Bestandsprovision, der für den Service bei uns bleibt. Cashback erhalten Sie bereits vom ersten investierten Euro an. Es gibt keine Mindestdepotgröße.',
          steps: {
            first: {
              title: 'Schritt 1',
              description: 'Daten eingeben',
            },
            second: {
              title: 'Schritt 2',
              description: 'Ausdrucken',
            },
            third: {
              title: 'Schritt 3',
              description: 'An uns senden',
            },
          },
          actions: {
            startNow: 'Jetzt direkt starten',
            startNowDescription:
              'Ihre Buchung wird erst verbindlich, wenn Sie uns Ihren Antrag per Post schicken',
          },
        },
        conditions: {
          title: '<mainText>Unsere Konditionen</mainText>',
          subtitle: 'Partnerbanken im Vergleich',
        },
        testimonials: {
          title: 'Das sagen Kunden',
          subtitle: '<greyText>Überzeugen Sie sich selbst</greyText>',
          cards: {
            vincentWillkomm: {
              name: 'Vincent Willkomm',
              profession: 'Finanzblogger, Hamburg',
              text: '“Um die Kosten von aktiven Investmentfonds in Richtung ETF-Niveau zu drücken, nutze ich das Cashback. Das garantiert die höchste Rückerstattung von Provisionen.”',
            },
            ingeborgK: {
              name: 'Ingeborg K.',
              profession: 'Pensionärin, Nürnberg',
              text: '“Ich nutze Rentablo Cashback bereits seit Januar 2018 und freue mich seitdem über pünktliche Auszahlungen von mehr als 1.200 Euro im Quartal.”',
            },
            konetzke: {
              name: 'Toralf Konetzke',
              profession: 'Unternehmer und Profisportler, Cottbus',
              text: '“Mit dem Cashback habt Ihr bei mir einen Elfmeter verwandelt. Kosten runter und Rendite hoch - perfekt! Danke und weiter so!”',
            },
            siebers: {
              name: 'Torsten Siebers',
              profession: 'Unternehmensberater, Hamburg',
              text: '“Als Nutzer bin ich immer wieder begeistert, mit welchem Enthusiasmus sich das Team von Rentablo dem Verbraucherschutz widmet und mit Fonds-Cashback auch umsetzt.”',
            },
          },
        },
        faq: {
          title: 'Noch Fragen?',
          subtitle: '<greyText>Ein paar Antworten zu häufigen Fragen.</greyText>',
          questions: [
            {
              id: 'how-to-book-cashback',
              title: 'Wie buche ich Fondsdiscount mit Fonds-Cashback?',
              text: 'Für den Fondskauf ohne Ausgabeaufschläge und Fonds-Cashback für bereits bestehende als auch neue Fondsanteile klicken Sie den Button “Jetzt starten” auf unserer Homepage. Folgen Sie nun der Schritt-für-Schritt Anleitung. Sie erhalten am Ende ein PDF,  welches Sie bitte an uns weiterleiten. Sollten Fragen aufkommen, melden Sie sich gerne bei unserem Kundenservice.',
            },
            {
              id: 'what-is-changing',
              title: 'Was ändert sich für mich als Fondssparer?',
              text: 'Beim Kauf von neuen Fondsanteilen sparen Sie künftig erheblich Abschlusskosten (Ausgabeaufschlag), außerdem erhalten Sie laufende Fondsgebühren als Fonds-Cashback erstattet. Ansonsten ändert sich nichts! Sie handeln Ihre Wertpapiere weiterhin wie gewohnt über Ihre Depotbank. Ihre Zugangsdaten dort ändern sich nicht. Ihre Fondsanteile sind und bleiben da, wo sie immer sind: bei Ihrer Depotbank.',
            },
            {
              id: 'when-do-I-receive-payout',
              title: 'Wann erfolgt die Auszahlung vom Fonds-Cashback?',
              text: 'Die Abrechnung und Auszahlung erfolgt quartalsweise; aufgrund der Abrechnungszyklen der Depotbanken können wir Ihnen erst zwei Monate nach Ablauf des jeweiligen Quartals Ihr individuelles Fonds-Cashback auszahlen.<br/>Auszahlungstermine sind demnach für das: <ol><li>Quartal, der 1. Juni</li><li>Quartal, der 1. September</li><li>Quartal, der 1. Dezember</li><li>Quartal, der 1. März des Folgejahres.</li></ol>',
            },
            {
              id: 'how-does-rentablo-earn-money',
              title: 'Wie verdient Rentablo Geld?',
              text: 'Bei Rentablo haben Sie keine Kosten, denn wir arbeiten ausschließlich erfolgsorientiert. <br />Sie werden immer ein Plus erzielen. Ehrenwort! Wovon wir leben? Von einem Teil der Bestandsprovision, der für den Service bei uns bleibt. Wir teilen uns die erhaltenen Bestandsprovisionen bis zu einer maximalen Grenze von 15,00 € im Monat. Ist diese Grenze erreicht, erhalten Sie die weitere Bestandsprovisionen zu 100 % ausgeschüttet. Weitere Informationen finden Sie hier im Forum: <br/><a href="https://forum.rentablo.de/t/was-kostet-rentablo-cashback-fuer-investmentfonds/626" target="_blank" rel="noreferrer">https://forum.rentablo.de/t/was-kostet-rentablo-cashback-fuer-investmentfonds/626.</a>',
            },
            {
              id: 'will-I-get-an-invoice',
              title: 'Erhalte ich eine Abrechnung?',
              text: 'Ja, natürlich! Sie erhalten zu jeder Fonds-Cashback-Ausschüttung eine genau Abrechnung. Diese können Sie in Ihrem Rentablo-Kundenkonto jederzeit einsehen. Rentablo steht für Transparenz, deswegen finden Sie unter "Details" die Höhe der Bestandsprovisionen zu jedem einzelnen Fonds.',
            },
            {
              id: 'no-depot-what-to-do',
              title: 'Ich habe kein Depot bei einer der Partnerbanken - was tun?',
              text: 'Eröffnen Sie über uns ein neues Depot! Auf dieser Seite sehen Sie die sechs Partnerbanken, mit denen Rentablo zusammenarbeitet. Wenn Ihre Depotbank hier nicht aufgeführt sein sollte, ist es notwendig, ein neues Depotkonto mit Rentablo als Betreuer bei einer der Partnerbanken zu eröffnen. Das dauert nur wenige Minuten. Hier finden Sie eine Übersicht über unsere Sonderkonditionen bei den Partnerbanken:<br/><a href="https://rentablo.de/cashback/comdirect" target="_blank" rel="noopener">https://rentablo.de/cashback/comdirect</a>',
            },
            {
              id: 'existing-depot-what-to-do',
              title: 'Ich habe bereits einen Vermittler in meinem Depot - wie gehe ich vor?',
              text: 'Beantragen Sie einen Betreuerwechsel! Wenn Sie bereits Fondsanteile bei einer dieser Depotbanken verwahren, ist es besonders einfach. Sie beantragen das Rentablo-Cashback und damit lediglich einen Betreuerwechsel. Haben Sie bereits einen Vermittler in Ihrem Depot, ist keine Kündigung notwendig. Der aktuelle Vermittler wird ausgetragen und Rentablo als neuer Vermittler im Depot eingetragen',
            },
            {
              id: 'may-rentablo-trade-in-my-name',
              title: 'Kann Rentablo in meinem Namen handeln?',
              text: 'Rentablo schließt den Handel aus. Wir werden weder Ihre Fondsanteile verkaufen noch Fondsanteile für Sie kaufen. Rentablo ist ein Service für Selbstentscheider bei Finanzangelegenheiten. Sie handeln und entscheiden immer selbst. Sie erhalten weder Anlageberatung noch irgendwelche "heißen Tipps", was Sie kaufen sollten.',
            },
            {
              id: 'is-rentablo-a-bank',
              title: 'Ist Rentablo eine Bank?',
              text: 'Nein, Rentablo ist keine Bank, sondern ein Discount-Fondsvermittler. Im Gegensatz zu einer Bank und beratenden Fondsvermittern verkaufen wir Ihnen nichts. Rentablo ist ein Service für Selbstentscheider mit dem Ziel, für Selbstentscheider die Kosten bestmöglich zu senken und damit die Fondsrendite zu erhöhen.',
            },
            {
              id: 'cashback-for-etf',
              title: 'Gibt es Fonds-Cashback auch bei ETFs?',
              text: 'Cashback gibt es i.d.R. für aktiv gemanagten Fonds, nicht für ETFs. Die Ankaufs- und Verkaufskosten bei ETFs sind ausserdem alleine zu tragen. Der Rabatt auf den Ausgabeaufschlag ist i.d.R. nur für aktiv gemanagte Fonds möglich. ETFs können aber in den gleichen Wertpapier-Depots gehalten werden und über unsere Partnerbanken gekauft und verkauft werden. Hier finden Sie einen Blog-Artikel über den Kostenvergleich zwischen ETFs und aktiv gemanagten Fonds: <a href="https://blog.rentablo.de/etf/" target="_blank" rel="noopener">https://blog.rentablo.de/etf/</a>',
            },
          ],
        },
        questions: {
          title: 'Weitere Fragen?',
          subtitle: 'Vieles beantworten wir auch in unserem Forum.',
          button: 'Jetzt zum Rentablo Forum',
          help: {
            name: 'Ina Ketzenberg',
            description: 'Kundensupport Rentablo',
            descriptionOnvista: 'Kundensupport onvista-Cashback',
          },
        },
        profit: {
          title: 'Jetzt rentabler Fondssparen!',
          subtitle: 'Rentablo hat bereits 3.000.000 € Cashback an Kunden ausgezahlt',
          button: 'Jetzt Auszahlung sichern',
        },
      },
      cashback: {
        50: 'Cashback-50',
        100: 'Cashback-100',
        title: 'Wir holen Ihre Gebühren zurück',
        keywords: 'Fondssparen, Fondssparer, Fonds-Cashback, Rabatt auf den Ausgabeaufschlag',
        description:
          'Kaufen Sie Fonds ohne Ausgabeaufschlag und erhalten die Verwaltungsvergütung als Cashback erstattet. Dadurch steigt Ihre Fonds-Rendite erheblich.',
        pageTopics: 'Fondssparen, Fondssparer, Fonds-Cashback',
        simpleCalculator: {
          title: 'Ihr Cashback berechnen:',
        },
        benefits: {
          left: 'Kostenfrei',
          right: 'Jederzeit kündbar',
          rankTitle: 'zufriedene Kunden',
          rankSubTitle: 'laut Proven Expert',
        },
        bigCallToAction: {
          title: 'Jetzt rentabler Fondsparen!',
          subTitleType: 'Schnell, einfach und sicher mehr Rendite!',
          processTitle: 'So bekommen Sie Cashback:',
          applyButton: 'Jetzt Fonds-Cashback beantragen',
          description:
            'Ihre Buchung wird erst verbindlich, wenn Sie uns Ihren Antrag per Post schicken!',
        },
        questions: {
          title: 'Noch Fragen?',
        },
      },
      cashbackCalculator: {
        navigation: {
          previous: 'Zurück',
        },
        title: 'Fondsdiscount-Rechner: Hier Vorteil berechnen',
        keywords:
          'Fonds-Provisionen, Bestandsprovision, Fondssparen, Fondssparer, Fonds-Cashback, Rabatt auf den Ausgabeaufschlag',
        description:
          'Berechnen Sie die Kosten Ihrer Investment-Fonds. Steigern Sie Ihre Fonds-Rendite indem Sie sich Ihre Provisionen mit Rentablo Cashback zurückholen.',
        pageTopics: 'Fonds-Provisionen, Fondssparer, Fonds-Cashback',
        subheading: 'Berechnen Sie hier die Cashback-Auszahlung für Ihre Fonds. ',
        goodToKnow: 'Gut zu wissen: Ihr Cashback gilt auch für bereits gekaufte Fonds.',
        simple: {
          amount: 'Betrag',
          investedAmount: 'Aktuell in Fonds investierter Betrag (ohne ETF)',
          plannedInvestment: 'Geplante Investition',
          year: 'Jahr',
          years: 'Jahre',
          oneTimeCommission: {
            label: 'Agio Rabatt',
          },
          cashback: {
            label: 'Cashback',
          },
        },
        detailed: {
          calculator: {
            clearAll: 'Liste löschen',
            chartHeading: 'Bestandsprovision: welche Bank zahlt wieviel?',
          },
          partnerBankSelector: {
            label: 'Bank',
            otherBank: 'Andere Bank',
          },
          fundModal: {
            labelAdd: 'Fonds hinzufügen',
            labelEdit: 'Fonds bearbeiten',
            apply: 'Zur Liste hinzufügen',
            cancel: 'Abbrechen',
          },
          funds: {
            label: 'Fondsauswahl',
            addFund: 'Ersten Fonds auswählen',
          },
          fundAutocomplete: {
            addMore: '+ Weiteren Fonds hinzufügen',
            placeholder: 'Name oder ISIN des Fonds',
            mostUsed: 'Häufig gesucht',
            others: 'Ihre Suche',
          },
          fundInput: {
            amount: 'Anlagebetrag',
            agio: 'Agio',
            portfolioCommission: 'Bestandsprovision p.a.',
            savingsPlanTooltip: 'Sparplan ab {{from}} bei {{bank}}',
            agioDiscount: '{{percent}} Rabatt',
            savingsPlan: 'SP',
            vl: 'VL',
            notAvailableAt: 'Fonds nicht verfügbar bei {{bank}}',
            limitedAgioDiscount: 'Agio Rabattierung nicht oder nur teilweise möglich',
          },
          aggregatedCommissionBox: {
            refundableAmount: 'Cashback-fähiger Betrag',
            totalAgio: 'Ihr Agio Rabatt',
            calculateCashback: '... oder exakt berechnen',
            depotComplete: 'Depot vollständig eingegeben?',
          },
          advantageBox: {
            rentabloAdvantage: 'Ihr Rentablo Vorteil',
            yourCashback: 'Ihr Cashback beträgt',
            yourAgioDiscount: 'Ihr Agio Rabatt beträgt',
            yourCost: 'Es fallen keine Kosten für Sie an!',
            applyButton: 'Jetzt Fonds-Cashback beantragen',
          },
          cashbackAreaChart: {
            chartHeading: 'Geschätztes Cashback für {{depotVolume}} Depotvolumen',
            rentabloFee: 'Rentablo erhält max. {{fee}} monatlich',
            rentabloFeeXs: 'Max. {{fee}} für Rentablo',
            rentabloFeeCB50: 'Für Rentablo',
            cashbackLabel: 'Ihr Fonds-Cashback',
            depotVolume: 'Depotvolumen',
            portfolioCommission: 'Bestandsprovision',
            fee: 'Für Rentablo',
            yourCashback: 'Ihr Cashback',
            perMonth: 'monatlich',
          },
          cashbackDisclaimer: {
            label:
              'Angaben basieren auf Original-Courtagelisten unserer Partnerbanken; alle Angaben ohne Gewähr',
            showChart: 'Bankenvergleichsgraph anzeigen',
          },
        },
        getCashbackNowButton: {
          label: 'Jetzt Fonds-Cashback beantragen',
        },
      },
      cashbackProcessIntroduction: {
        enterData: 'Daten eingeben',
        print: 'Ausdrucken',
        sendToUs: 'An uns senden',
      },
      header: {
        manageMyBookings: 'Cashback Buchungen verwalten',
        financeManager: 'Portfoliomanager',
        cashback: 'Fonds-Cashback',
        blog: 'Blog',
        knowledgeBase: 'Wissen',
        logIn: 'Login',
        logOut: 'Logout',
        signUp: 'Registrieren',
        dashboard: 'Dashboard',
        forum: 'Forum & FAQ',
      },
      navigation: {
        cancel: 'Abbrechen',
        previous: 'Zurück',
        next: 'Weiter',
      },
      footer: {
        column1: {
          title: 'Unternehmen',
          items: [
            // {
            //   href: 'https://wealthapi.eu/ueber-uns/',
            //   title: 'Über uns',
            // },
            {
              href: 'https://wealthapi.eu/karriere/',
              title: 'Jobs & Karriere',
            },
            {
              href: 'https://www.financequality.net/partnerprogramme/rentablo-fond-cashback/',
              title: 'Partnerprogramm',
            },
            {
              href: 'https://wealthapi.eu',
              title: 'Geschäftskunden',
            },
          ],
        },
        column2: {
          title: 'Features',
          items: [
            {
              href: 'https://rentablo.de/software-depotverwaltung',
              title: 'Software Depotverwaltung',
            },
            {
              href: 'https://rentablo.de/portfolio-performance',
              title: 'Portfolio Performance',
            },
            {
              href: 'https://rentablo.de/portfolio-optimierung',
              title: 'Portfolio Optimierung',
            },
            {
              href: 'https://rentablo.de/kostenloser-finanzmanager-online',
              title: 'Kostenloser Portfoliomanager',
            },
          ],
        },
        column3: {
          title: 'Wissen & Finanztools',
          items: [
            {
              href: 'https://forum.rentablo.de/',
              title: 'Forum & FAQ',
            },
            {
              href: 'https://blog.rentablo.de/',
              title: 'Finanzblog',
            },
            {
              href: 'https://rentablo.de/elektronisches-haushaltsbuch',
              title: 'Elektronisches Haushaltsbuch',
            },
            {
              href: 'https://blog.rentablo.de/vergleichsrechner-und-finanztools/',
              title: 'Vergleichsrechner',
            },
            {
              href: 'https://rentablo.de#cashback-calculator',
              title: 'Fondsdiscount-Rechner',
            },
          ],
        },
        column4: {
          title: 'Finanztools & Wissen',
          items: [
            {
              href: 'https://forum.rentablo.de/',
              title: 'Forum & FAQ',
            },
            {
              href: 'https://blog.rentablo.de/',
              title: 'Finanzblog',
            },
            {
              href: 'https://rentablo.de/kostenloser-finanzmanager-online',
              title: 'Kostenloser Portfoliomanager',
            },
            {
              href: 'https://rentablo.de/elektronisches-haushaltsbuch',
              title: 'Elektronisches Haushaltsbuch',
            },
            {
              href: 'https://blog.rentablo.de/vergleichsrechner-und-finanztools/',
              title: 'Vergleichsrechner',
            },
          ],
        },
        subFooterItems: [
          {
            href: 'https://rentablo.de/v2/impressum',
            title: 'Impressum',
          },
          {
            href: 'https://rentablo.de/v2/privacy',
            title: 'Datenschutz',
          },
          {
            href: 'https://rentablo.de/v2/agb',
            title: 'AGBs',
          },
          {
            href: 'https://rentablo.de/v2/disclaimer',
            title: 'Haftungsausschluss',
          },
        ],
      },
      existingSubscriptionsAlert: {
        message: 'Sie haben bereits eine Fonds-Cashback Buchung.',
        message_plural: 'Sie haben bereits {{count}} Fonds-Cashback Buchungen.',
        manageLink: 'Verwalten',
      },
      bankOverview: {
        blockTitle: 'Konditionen unserer Partnerbanken im Vergleich:',
        testWinner: 'Testsieger',
        depotFee: 'Depotgebühr',
        refundPortfolioCommission: 'Rückerstattung Bestandsprovision',
        discountOneTimeCommission: 'Rabatt auf Ausgabeaufschlag',
        availableFunds: 'Handelbare Fonds',
        savingsPlanPrice: 'Kosten für Fondssparplan',
        averageCashback: 'Durchschnittliches Cashback p.a.',
        rating: 'Unser Rating',
        costWarning: 'Vorsicht Kosten!',
        card: {
          more: 'Mehr Erfahren',
          rank: 'Unser Rating:',
        },
        comdirect: {
          availableFunds: 'über 24.000',
        },
        augsburgerAktienbank: {
          availableFunds: 'über 12.000',
        },
        dab: {
          availableFunds: 'über 13.000',
        },
        ebase: {
          availableFunds: 'über 6.900',
        },
        fondsdepotBank: { availableFunds: 'über 7.000', depotFee: '0€ bis 39€' },
        fbb: { availableFunds: 'über 7.000', depotFee: '0€ bis 50€' },
        metzler: { availableFunds: 'über 7.500' },
        morgenFund: {
          availableFunds: '0,00 - 50,00 €',
          depotFee: '0€ bis 50€',
          headerTitle: 'Ehemals DWS Depot',
        },
      },
      products: {
        title: 'Rentablo Portfoliomanager - Premium Pakete',
        tableTitle: 'Detailübersicht',
        free: {
          subheader: 'Kostenlose Portfolio-Verwaltung',
          features: [
            '1 Bankverbindung',
            '3 Jahre historische Daten',
            'Dividendenplaner',
            'Support über das Rentablo Forum',
          ],
        },
        plus: {
          subheader: 'für Investoren im Vermögensaufbau',
          features: [
            'alle <strong>Basis-Funktionen inklusive</strong>',
            '<strong>5</strong> Bankverbindungen',
            '<strong>6 Jahre</strong> historische Daten',
            '<strong>Intraday</strong> Kurse',
            'E-Mail Support innerhalb von 5 Tagen',
            'Fremdwährungen',
          ],
        },
        pro: {
          subheader: 'für Investoren, die ihr Vermögen selbst professionell verwalten',
          features: [
            'alle <strong>PLUS Funktionen inklusive</strong>',
            '<strong>unbegrenzt</strong> viele Bankverbindungen',
            '<strong>Aktien</strong>-Fundamentaldaten',
            '<strong>10 Jahre</strong> historische Daten',
            '<strong>Telefon-Support</strong>',
          ],
          comingSoonFeatures: [
            '<strong>Fonds</strong>-Fundamentaldaten',
            '<strong>Reports</strong> und Export-optionen',
            'Rendite-Risiko-<strong>Simulator</strong>',
          ],
        },
        section: {
          depots: 'Depots',
          investments: 'Investments',
          comingSoon: 'In Kürze verfügbar',
        },
        features: {
          bankConnection: 'Bankverbindungen',
          historyData: 'Historische Daten',
          support: 'Support',
          financialGoals: 'Finanzziele',
          crypto: 'Kryptowährungen',
          foreignCurrencies: 'Fremdwährungen',
          goldAndMaterialAssets: 'Edelmetalle und Sachwerte',
          dividends: 'Dividendenplaner',
          intraday: 'Intraday Kurse',
          fundamentalStockData: 'Aktien-Fundamentaldaten',
          fundamentalFundsData: 'Fonds-Fundamentaldaten',
          reports: 'Reports und Export-optionen',
          taxSimulation: 'Steuer-Simulator',
          riskSimulation: 'Rendite-Risiko-Simulator',
          underDevelopment: 'in Entwicklung',
        },
        historyData: {
          free: '3 Jahre',
          plus: '6 Jahre',
          pro: '10 Jahre',
        },
        support: {
          free: 'Rentablo Forum',
          plus: 'Email',
          pro: 'Email und Telefon',
        },
        priceDesc: {
          discountTime: 'Einführungspreis bis',
          line1: 'pro Monat, inkl. MwSt.',
          line2yearly: 'bei jährlicher Zahlweise',
          line2monthly: 'bei monatlicher Zahlweise',
        },
        register: 'Registrieren',
        subscribe: '30 Tage kostenlos testen',
        subscribeSmall: 'Jetzt testen',
        moreDetails: 'Alle Funktionen im Detail',
        moreDetailsTableName: '{{name}} Funktionen',
        monthlySwitchTitle: 'monatliche zahlweise',
        currentSubscription: 'IHR PLAN',
        changeSubscription: 'Plan wählen',
        sevenDaysNotificationMessage:
          '* Keine Sorge: Wir erinnern Sie 7 Tage vor Ablauf des Testzeitraums.',
        yourPlan: 'Ihr Plan',
        detailsAndSettingsLink: 'Details und Einstellungen',
        discountStamp: {
          shortTime: 'Nur für kurze Zeit:',
          discount: 'Rabatt',
        },
      },
      banksComparisonTable: {
        accountManagement: 'Depotführung p.a.',
        availableFunds: 'Verfügbare Fonds',
        numberOfFunds: 'Anzahl Fonds',
        numberOfFundsSavingPlan: 'Davon Sparplanfähig',
        fundConditions: 'Konditionen Fonds',
        fundPurchasePrice: 'Fondskauf',
        fundSavingsPlanPrice: 'Fonds-Sparplan',
        commissionReimbursement: 'Vergleichswerte Provisions-Rückerstattung',
        averageReimbursement: 'Durchschnittliche Rückerstattung p.a.',
        ETFConditions: 'Konditionen ETF',
        ETFPurchasePrice: 'ETF-Kauf',
        ETFSavingsPlanPrice: 'ETF-Sparplan',
        stockExchangeTransactions: 'Konditionen Börsengeschäfte',
        exchangeTrading: 'Börsenhandel möglich',
        securitiesTrading: 'Handel Wertpapiere',
        drawings: 'Zeichnungen',
        capitalAccumulationBenefits: 'Vermögenswirksame Leistungen',
        VLSavingsPlan: 'VL Sparplan möglich',
        others: 'Sonstiges',
        service: 'Service',
        reachability: 'Erreichbarkeit',
        tel: 'Telefonnummer',
        onlineBanking: 'Online-Banking',
        financeManager: 'Integration Portfoliomanager',
        rating: 'Unser Rating',
        explanations: {
          line1: 'TS: Transaktionssumme AA: Ausgabeaufschlag n.a.: nicht angeboten',
          line2:
            'Bei Fonds der Union Investment und der Deka Investment können Ausgabeaufschläge anfallen, da diese Gesellschaften die Rabattierung u.U. verhindern. Nutzen Sie unseren <1>Detailrechner</1>, um diese Fonds zu erkennen.',
          line3:
            '* Vergleichswerte basieren auf einer repräsentativen Menge von 2856 Fonds, die bei allen Partnerbanken gehandelt werden können.',
          line4: 'Alle Angaben ohne Gewähr.',
        },
        comdirect: {
          name: 'comdirect',
          accountManagement: '0 €',
          numberOfFunds: '25.450',
          numberOfFundsSavingPlan: '2.400',
          fundPurchasePrice: 'ab 17,50 €',
          fundPurchasePriceNotice:
            '0-10.000\u00A0€: 17,50\u00A0€; 10.000-50.000\u00A0€: 35,00\u00A0€; 50.000-100.000\u00A0€: 70,00\u00A0€; darüber 95,00\u00A0€',
          fundSavingsPlanPrice: '0,95\u00A0€',
          averageReimbursement: '<1>0,56%</1>',
          ETFPurchasePrice: '0,25% oder 19,95\u00A0€ <br> gemäß Konditionsmodell',
          ETFSavingsPlanPrice: '1,5% TS',
          exchangeTrading: 'ja',
          securitiesTrading: '0,25%; min. 10\u00A0€ <br> <1>19,95\u00A0€ Flatfee</1>',
          securitiesTradingNotice: 'Flatfee Tarif für große Orders wählbar',
          drawings: '0,25%; min. 10\u00A0€ <br> <1>19,95\u00A0€ Flatfee</1>',
          drawingsNotice: 'Flatfee Tarif für große Orders wählbar',
          VLSavingsPlan: 'nein',
          service: 'Mo-So<br> 0h - 24h',
          reachability: 'gut',
          tel: '+49\u00A0(0)\u00A04106\u00A0-\u00A0708\u00A025\u00A000',
          onlineBanking: 'sehr gut',
          financeManager: 'Inklusive',
          financeManagerNotice: 'One-Click Import exklusiv für Cashback Kunden',
        },
        dab: {
          name: 'DAB PNB Paribas',
          accountManagement: '0\u00A0€',
          numberOfFunds: '16.600',
          numberOfFundsSavingPlan: '1.600',
          fundPurchasePrice: '0,25%; min. 10\u00A0€<br> <1>15,00\u00A0€ Flatfee</1>',
          fundPurchasePriceNotice:
            'Flatfee Tarif für große Orders wählbar; Courtage kann zusätzlich anfallen',
          fundSavingsPlanPrice: '2\u00A0€',
          fundSavingsPlanPriceNotice: 'Kostenfrei im 15\u00A0€ Flatfee Tarif',
          averageReimbursement: '0,56%',
          ETFPurchasePrice: '0,25%; min. 10\u00A0€<br> <1>15,00\u00A0€ Flatfee</1>',
          ETFPurchasePriceNotice:
            'Flatfee Tarif für große Orders wählbar; Courtage kann zusätzlich anfallen',
          ETFSavingsPlanPrice: '2,50\u00A0€ + 0,25 %',
          exchangeTrading: 'ja',
          securitiesTrading: '0,25%; min. 10\u00A0€<br> <1>15,00\u00A0€ Flatfee</1>',
          securitiesTradingNotice:
            'Flatfee Tarif für große Orders wählbar; Courtage kann zusätzlich anfallen',
          drawings: '0,25%<br> min. 15\u00A0€',
          VLSavingsPlan: 'nein',
          service: 'Mo-Fr<br> 9h - 17h',
          reachability: 'gut',
          tel: '+49\u00A0(0)\u00A069\u00A07193\u00A00',
          onlineBanking: 'gut',
          financeManager: 'Inklusive',
          financeManagerNotice: 'One-Click Import exklusiv für Cashback Kunden',
        },
        ebase: {
          name: 'FNZ',
          accountManagement: '0 - 53\u00A0€',
          accountManagementNotice:
            'Minderjährigen-Depot: 0\u00A0€; VL-Depot: 12\u00A0€; Standard  Online-Depot: 53\u00A0€; Business-Depot: 95,20\u00A0€',
          numberOfFunds: '10.200',
          numberOfFundsSavingPlan: '7.600',
          fundPurchasePrice: '0\u00A0€',
          fundSavingsPlanPrice: '0\u00A0€',
          averageReimbursement: '0,49%',
          ETFPurchasePrice: '0,20 % TS + Spesen',
          ETFSavingsPlanPrice: '0,20 % TS + Spesen',
          exchangeTrading: 'ja',
          securitiesTrading: 'Separates Depot benötigt',
          drawings: 'n.a.',
          VLSavingsPlan: 'ja, 12\u00A0€ p.a.',
          service: 'Mo-Fr<br> 8h - 18h',
          reachability: 'sehr gut',
          tel: '+49\u00A089\u00A045460\u00A0-\u00A0890',
          onlineBanking: 'befriedigend',
          financeManager: 'Inklusive',
          financeManagerNotice: 'One-Click Import exklusiv für Cashback Kunden',
        },
        fondsdepotbank: {
          name: 'Fondsdepotbank',
          accountManagement: '0 - 39 €',
          accountManagementNotice: 'Minderjährigen-Depot: 0 €; Fondsdepot Online: 39 €;',
          numberOfFunds: '11.000',
          numberOfFundsSavingPlan: '4.000',
          fundPurchasePrice: '0\u00A0€',
          fundSavingsPlanPrice: '0\u00A0€',
          averageReimbursement: '0,52%',
          ETFPurchasePrice: '0,21% TS',
          ETFSavingsPlanPrice: '0,21% TS',
          exchangeTrading: 'nein',
          securitiesTrading: 'n.a.',
          drawings: 'n.a.',
          VLSavingsPlan: 'ja, 12\u00A0€ p.a.',
          service: 'Mo-Fr<br> 8h - 20h',
          tel: '+49\u00A0(0)\u00A09281\u00A072583000',
          reachability: 'gut',
          onlineBanking: 'befriedigend',
          financeManager: 'Inklusive',
          financeManagerNotice: 'One-Click Import exklusiv für Cashback Kunden',
        },
        ffb: {
          name: 'FFB: FIL Fondsbank',
          accountManagement: '0 - 50 €',
          accountManagementNotice:
            'JuniorDepot: 0 €, Fondsdepot: 0,25 % vom durchschnittlichen Depotwert p. a. (mind. 25 €, max. 50 €), FondsdepotPlus: 45 €',
          numberOfFunds: '9.500',
          numberOfFundsSavingPlan: '8.820',
          fundPurchasePrice: '2\u00A0€',
          fundSavingsPlanPrice: '0\u00A0€',
          averageReimbursement: '0,48%',
          ETFPurchasePrice: '0,2% TS',
          ETFSavingsPlanPrice: '0,2% TS',
          exchangeTrading: 'nein',
          securitiesTrading: 'n.a.',
          drawings: 'n.a.',
          VLSavingsPlan: 'ja, 12\u00A0€ p.a.',
          service: 'Mo-Fr<br> 8h - 18h',
          reachability: 'sehr gut',
          tel: '+49\u00A0(0)\u00A069\u00A0770\u00A060\u00A0200',
          onlineBanking: 'ausreichend',
          financeManager: 'Inklusive',
          financeManagerNotice: 'One-Click Import exklusiv für Cashback Kunden',
        },
        morgenfund: {
          name: 'MorgenFund',
          accountManagement: '0,00 - 50,00 €',
          accountManagementNotice:
            'Minderjährigen-Depot: 0 €; Depot Classic online 8 €; Depot Classic 12 €; Depot Plus online 45 €; Depot Plus 50 €',
          numberOfFunds: '2.750',
          numberOfFundsSavingPlan: '2.470',
          fundPurchasePrice: '0 €',
          fundSavingsPlanPrice: '0 €',
          averageReimbursement: '0,44%',
          ETFPurchasePrice: 'nicht möglich',
          ETFSavingsPlanPrice: 'nicht möglich',
          exchangeTrading: 'nein',
          securitiesTrading: 'nein',
          drawings: 'nein',
          VLSavingsPlan: 'ja, 12 € p.a.',
          service: 'k.A.',
          reachability: 'befriedigend',
          tel: '+49 69 566080-020',
          onlineBanking: 'unzureichend',
          financeManager: 'inklusive',
          financeManagerNotice: 'One-Click Import exklusiv für Cashback Kunden',
        },
      },
      banks: {
        comdirect: {
          title: 'Vergleichen Sie unsere Depotbanken',
        },
        ebase: {
          title: 'Vergleichen Sie unsere Depotbanken',
        },
        fondsdepotbank: {
          title: 'Vergleichen Sie unsere Depotbanken',
        },
        dab: {
          title: 'Vergleichen Sie unsere Depotbanken',
        },
        ffb: {
          title: 'Vergleichen Sie unsere Depotbanken',
        },
        dws: {
          title: 'Vergleichen Sie unsere Depotbanken',
          info: {
            message:
              'Rentablo-Cashback ist nur für bestehende DWS Depots verfügbar. Die Eröffnung eines neuen DWS Depots über Rentablo ist aktuell nicht möglich. Bitte eröffnen Sie ggf. ein Depot bei einer unserer weiteren Partnerbanken.',
          },
        },
        morgenfund: {
          title: 'Vergleichen Sie unsere Depotbanken',
        },
      },
    },
    onvista: {
      landingMain: {
        detailCalculator: {
          title: 'Hier Vorteil berechnen:',
          subtitle:
            'Gut zu wissen: Selbst wenn Sie die Fondsanteile bereits gekauft haben, können Sie immer noch an der laufenden Bestandsprovision (Cashback) partizipieren, indem Sie zu Onvista-Cashback wechseln.',
        },
        callToAction: {
          title: 'Jetzt rentabler Fondssparen!',
          subTitleType1: 'Holen Sie sich Ihr Geld zurück und sichern Sie Ihre Cashback Prämie!',
          subTitleType2: 'Schnell, einfach und sicher mehr Rendite!',
          applyButton: 'Hier Fonds-Cashback beantragen',
          applyButtonXs: 'Hier beantragen',
        },
        faq: {
          title: 'FAQs',
          applyButton: 'Jetzt Fonds-Cashback beantragen',
          questions: [
            {
              question: 'Was ist onvista-cashback; und wer ist Rentablo?',
              answer:
                'onvista-cashback ist eine Zusammenarbeit mit Rentablo, einem Fondsdiscounter "2.0". Wie bei anderen Fondsdiscountern können Sie mit onvista-cashback fast alle Fonds ohne Ausgabeaufschlag kaufen. Wir gehen beim aber Cashback noch einen Schritt weiter: Als onvista-cashback Kunde erhalten Sie generell 80 % der Bestandsprovision eines Fonds erstattet, und zwar quartalsweise.  Wie viel, hängt vom Fonds und der jeweiligen Depotbank ab. Sie können das direkt hier auf der Seite ausrechnen.',
            },
            {
              question: 'Wie buche ich Cashback?',
              answer:
                'Sie können Ihr Fondscashback ganz einfach auf dieser Webpage buchen. Klicken Sie hierfür auf den Button “Hier Fonds-Cashback beantragen” und folgenden den Fragen in der Antragsstrecke. Am Ende erhalten Sie eine PDF, welche Sie bitte ausdrucken, unterschreiben und  an uns senden.',
            },
            {
              question: 'Ist Rentablo eine Bank?',
              answer:
                'Nein, Rentablo ist keine Bank, aber von der BaFin (Aufsicht) kontrolliert. Im Gegensatz zu einer Bank jedoch, verkaufen wir Ihnen nichts. Rentablo ist ein Service für Selbstentscheider bei Finanzangelegenheiten. Sie handeln und entscheiden immer selbst. Sie erhalten weder Anlageberatung noch irgendwelche "heißen Tipps", was Sie kaufen sollten. Via onvista-Cashback holen Sie einen großen Teil der Fondskosten zurück, die sonst bei der Bank verbleiben oder von Ihrem Finanzvermittler einbehalten werden.',
            },
            {
              question: 'Wo gehen die Fondsanteile hin?',
              answer:
                'Ihre Fondsanteile sind und bleiben da, wo sie immer sind: bei Ihrer Depotbank. Rentablo darf weder Ihre Fondsanteile verkaufen noch Fondsanteile für Sie kaufen. Wie gesagt: Das ist ein Service für Selbstentscheider. Bei Ihrer bisherigen oder neuen Depotbank gibt es nur einen Betreuerwechsel. Rentablo wird als Ihr Betreuer eingetragen und verzichtet sofort auf Abschlussprovisionen (Ausgabeaufschläge) und schüttet Bestandsprovisionen als Cashback zu den hervorragenden onvista-cashback Konditionen an Sie aus.',
            },
            {
              question: 'Was muss ich dafür tun?',
              answer:
                'Nicht viel. Auf dieser Seite sehen Sie die acht onvista-Cashback Partnerbanken. Wenn Sie bereits Fondsanteile bei einer dieser Depotbanken verwahren, ist es besonders einfach. Sie beantragen das onvista-Cashback und damit lediglich einen Betreuerwechsel. Das dauert nur wenige Minuten. Wenn Ihre Depotbank hier nicht aufgeführt sein sollte, ist es notwendig, ein neues Depotkonto mit Rentablo als Betreuer bei einer der sieben Partnerbanken zu eröffnen. Das geht ebenfalls innerhalb weniger Minuten.',
            },
            {
              question: 'Was kostet mich onvista-Cashback?',
              answer:
                'Cashback wird Sie niemals etwas kosten - Sie werden immer ein Plus erzielen (auch wenn wir etwas daran verdienen). Ehrenwort! Wovon wir leben? Von einem Teil der Bestandsprovision, der für den Service bei uns bleibt. Cashback erhalten Sie bereits vom ersten investierten Euro an. Es gibt keine Mindestdepotgröße. Sie erhalten von Anfang an 80 % der an uns ausgeschütteten Bestandsprovision.',
            },
          ],
        },
        testimonials: {
          clientsTitle: 'Das sagen Kunden',
          expertsTitle: 'Das sagen Finanzexperten',
          bothTitle: 'Das sagen Kunden und Finanzexperten',
          willkomm: {
            name: 'Vincent Willkomm',
            description: 'Finanzblogger, Hamburg',
            text: 'Um die Kosten von aktiven Investmentfonds in Richtung ETF-Niveau zu drücken, nutze ich das Fonds-Cashback. Das garantiert die höchste Rückerstattung von Provisionen.',
          },
          siebers: {
            name: 'Torsten Siebers',
            description: 'Unternehmensberater, Hamburg',
            text: 'Als Nutzer bin ich immer wieder begeistert, mit welchem Enthusiasmus sich das Team von Rentablo dem Verbraucherschutz widmet und mit Fonds-Cashback auch umsetzt.',
          },
          bukow: {
            name: 'E. Bukow',
            description: 'kfm. Angestellte, Düsseldorf',
            text: 'Besten Dank Rentablo! Die Abrechnungen vom Cashback kommen pünktlich und werden auf den Cent genau ausgewiesen.',
          },
          konetzke: {
            name: 'Toralf Konetzke',
            description: 'Unternehmer und Profisportler, Cottbus',
            text: 'Mit dem Cashback habt Ihr bei mir einen Elfmeter verwandelt. Kosten runter und Rendite hoch - perfekt! Danke und weiter so!',
          },
          ingeborg: {
            name: 'Ingeborg K.',
            description: 'Pensionärin, Nürnberg',
            text: 'Ich nutze Rentablo Cashback bereits seit Januar 2018 und freue mich seitdem über pünktliche Auszahlungen von mehr als 1.200 Euro im Quartal.',
          },
          handelsblatt: {
            title: 'Handelsblatt',
            text: '',
          },
          ard: {
            title: 'börse ARD',
            text: '',
          },
          br: {
            title: 'BR',
            text: '',
          },
          fondsProfessionell: {
            title: 'Fonds-Professionell',
            text: '',
          },
          tagesschau: {
            title: 'Tagesschau',
            text: '',
          },
          sueddeutsche: {
            title: 'Süddeutsche Zeitung',
            text: 'Wer in Fonds anlegt, muss auf die Kosten achten. Jetzt werben Anbieter mit der Erstattung von Provisionen.',
          },
          boersede: {
            title: 'boerse.de',
            text: '',
          },
          welt: {
            title: 'Die Welt, Future of Finance',
            text: 'Wer sein Vermögen in Zeiten des Nullzinses erhalten will, muss es diversifizieren. Hierzu bieten sich Investment-Fonds an - leider oft zu überhöhten Gebühren. Mit seinem Fonds-Cashback bietet das FinTech Rentablo Anlegern erstmals die Möglichkeit, sich einen Großteil der Provisionen zurückzuholen.',
          },
          wiwo: {
            title: 'Wirtschaftswoche',
            text: '...das Fintech Rentablo, “eine Mischung aus Aldi und Deutsche Bank”.',
          },
          focusMoney: {
            title: 'Focus Money, Fairstes Produktangebot',
            text: 'Savedo und Rentablo bieten das beste Preis-Leistungs-Verhältnis.',
          },
          versicherungsmagazin: {
            title: 'Versicherungsmagazin, Fintech unter der Lupe',
            text: 'Rentablo - Leidenschaftliche Investoren mit Sinn fürs Kollektiv',
          },
        },
      },
      cashbackCalculator: {
        navigation: {
          previous: 'Zurück',
        },
        title: '<mainText>Fondsdiscount-Rechner</mainText>',
        subTitle: 'Ihren Vorteil berechnen',
        keywords:
          'Fonds-Provisionen, Bestandsprovision, Fondssparen, Fondssparer, Fonds-Cashback, Rabatt auf den Ausgabeaufschlag',
        description:
          'Berechnen Sie die Kosten Ihrer Investment-Fonds. Steigern Sie Ihre Fonds-Rendite indem Sie sich Ihre Provisionen mit onvista-Cashback zurückholen.',
        pageTopics: 'Fonds-Provisionen, Fondssparer, Fonds-Cashback',
        subheading: 'Berechnen Sie hier die Cashback-Auszahlung für Ihre Fonds. ',
        goodToKnow: 'Gut zu wissen: Ihr Cashback gilt auch für bereits gekaufte Fonds.',
        simple: {
          amount: 'Betrag',
          investedAmount: 'Aktuell in Fonds investierter Betrag (ohne ETF)',
          plannedInvestment: 'Geplante Investition',
          year: 'Jahr',
          years: 'Jahre',
          oneTimeCommission: {
            label: 'Agio Rabatt',
          },
          cashback: {
            label: 'Cashback',
          },
        },
        detailed: {
          calculator: {
            clearAll: 'Liste löschen',
            chartHeading: 'Bestandsprovision: Bank zahlt wieviel?',
          },
          partnerBankSelector: {
            label: 'Ihre Depotbank',
            otherBank: 'Andere Bank',
          },
          fundAutocomplete: {
            placeholder: 'Name oder ISIN des Fonds',
            mostUsed: 'Häufig gesucht',
            others: 'Ihre Suche',
            addMore: '+ Weiteren Fonds hinzufügen',
          },
          fundInput: {
            amount: 'Anlagebetrag',
            agio: 'Agio',
            portfolioCommission: 'Bestandsprovision p.a.',
            savingsPlanTooltip: 'Sparplan ab {{from}} bei {{bank}}',
            agioDiscount: '{{percent}} Rabatt',
            savingsPlan: 'SP',
            vl: 'VL',
            notAvailableAt: 'Fonds nicht verfügbar bei {{bank}}',
          },
          aggregatedCommissionBox: {
            refundableAmount: 'Cashback-fähiger Betrag',
            totalAgio: 'Ihr Agio Rabatt',
            calculateCashback: '... oder exakt berechnen',
            depotComplete: 'Depot vollständig eingegeben?',
          },
          advantageBox: {
            rentabloAdvantage: 'Ihr onvista-Cashback Vorteil',
            yourCashback: 'Ihr Cashback beträgt',
            yourAgioDiscount: 'Ihr Agio Rabatt beträgt',
            yourCost: 'Es fallen keine Kosten für Sie an!',
            applyButton: 'Jetzt Fonds-Cashback beantragen',
          },
          cashbackAreaChart: {
            chartHeading: 'Geschätztes Cashback für {{depotVolume}} Depotvolumen',
            rentabloFee: 'Wir erhalten max. {{fee}} monatlich',
            rentabloFeeXs: 'Max. {{fee}} für uns',
            rentabloFeeCB50: 'Für uns',
            cashbackLabel: 'Ihr Fonds-Cashback',
            depotVolume: 'Depotvolumen',
            portfolioCommission: 'Bestandsprovision',
            fee: 'Für uns',
            yourCashback: 'Ihr Cashback',
            perMonth: 'monatlich',
          },
          cashbackDisclaimer: {
            label:
              'Angaben basieren auf Original-Courtagelisten unserer Partnerbanken; alle Angaben ohne Gewähr',
            showChart: 'Bankenvergleichsgraph anzeigen',
          },
          funds: {
            label: 'Fondsauswahl',
            addFund: 'Ersten Fonds auswählen',
          },
        },
        getCashbackNowButton: {
          label: 'Jetzt Fonds-Cashback beantragen',
        },
      },
      banks: {
        comdirect: {
          title: 'Fair und günstig: onvista & comdirect',
        },
        ebase: {
          title: 'Fair und günstig: onvista & FNZ',
        },
        fondsdepotbank: {
          title: 'Fair und günstig: onvista & Fondsdepot Bank',
        },
        dab: {
          title: 'Fair und günstig: onvista & DAB BNP Paribas',
        },
        ffb: {
          title: 'Fair und günstig: onvista & FIL Fondsbank (FFB)',
        },
        dws: {
          title: 'Fair und günstig: onvista & DWS',
          info: {
            message:
              'onvista-Cashback ist nur für bestehende DWS Depots verfügbar. Die Eröffnung eines neuen DWS Depots über Rentablo ist aktuell nicht möglich. Bitte eröffnen Sie ggf. ein Depot bei einer unserer weiteren Partnerbanken.',
          },
        },
        morgenfund: {
          title: 'Vergleichen Sie unsere Depotbanken',
        },
      },
    },
  },
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    fallbackLng: 'en',
    lng: process.env.REACT_APP_LANGUAGE || 'de',
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
